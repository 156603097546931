import React from 'react'
import {Link, useNavigate} from "react-router-dom";
import { Checklist } from "../../components/Checklist";
import { redirectUnauthenticatedToLoginAndReturnAfter } from "../../models/User";
import { Checklist as ChecklistIcon } from "../../svg";
import {useLoaderData} from "../../hooks/useLoaderData";

import {metaDataT, SeoDataT} from "../../types/GlobeType";
import Loader from "../../components/Loader";

export let meta = ({ data }: { data: SeoDataT }) => {
	let meta_object: metaDataT = {};
	meta_object.title = "My saved checklists - Fit For Reuse";
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};

// @ts-ignore
export default function Index({ user, token }) {
	const navigate = useNavigate();
	if (!user) {
		// eslint-disable-next-line no-restricted-globals
		redirectUnauthenticatedToLoginAndReturnAfter(location.pathname, user, navigate);
		throw new Error("Failed to retrieve user.");
	}

	const [saved_checklists_data, isLoading] = useLoaderData(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_checklists`, {checklists: []},{
		Authorization: `Bearer ${token}`
	});

	let checklist_data = saved_checklists_data;
	let all_checklists = checklist_data.checklists;
	let saved_checklists: any[] = [];
	all_checklists.forEach((checklist: { id: any; }) => {
		// @ts-ignore
		if (user.meta.saved_checklists.includes(checklist.id)) {
			saved_checklists.push(checklist);
		}
	});

	if (isLoading) {
		return <main className={`my_account_content my_checklists no_checklists`}><Loader /></main>
	}

	return (
		<main className={`my_account_content my_checklists${saved_checklists.length > 0 ? "" : " no_checklists"}`}>
			{saved_checklists.length > 0 ? (
				saved_checklists.map((checklist) => {
					return <Checklist key={checklist.id} title={checklist.title} icon={checklist.icon} slug={checklist.slug} />;
				})
			) : (
				<>
					<ChecklistIcon />
					<p className="no_checklists_message">
						Your checklists section is currently empty, click below to browse documentation and start adding
					</p>
					<Link to="/checklists">
						<button className="button">Add some checklists</button>
					</Link>
				</>
			)}
		</main>
	);
}
