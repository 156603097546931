import React from 'react'
import { Link } from 'react-router-dom'
import {prop, uniqBy} from "ramda"

export function GalleryPreview({
	images,
	section,
	subsection,
	set_show_gallery,
	set_gallery_item,
}: {
	images: GalleryItem[];
	section: string;
	subsection: string;
	set_show_gallery: CallableFunction;
	set_gallery_item: CallableFunction;
}) {
	let added_more_images_link;
	if (images.length > 9 && images[8]?.image_id !== 0) {
		const original_images_length = images.length;
		images.splice(8, 0, {
			image_id: 0,
			preview_markup: `+${original_images_length - 8} more`,
			markup: `+${original_images_length - 8} more`,
			link_text: images[0].link_text,
			caption: false,
		});
		added_more_images_link = true;
	}
	return (
		<section className="gallery">
			<header>
				<h4>Gallery</h4>
				<p>Click to open images</p>
			</header>
			<div className="images">
				{uniqBy(prop('link_text'),images).map((image_group) => {
					if (image_group.image_id === 0) {
						return (
							<Link
								className="more_images"
								to={`/documentation/${section}/${subsection}/gallery/${image_group.link_text}`}
								key="more_images"
								dangerouslySetInnerHTML={{ __html: image_group.preview_markup }}
								onClick={(event) => {
									event.preventDefault();
									window.history.replaceState(
										{},
										"",
										`/documentation/${section}/${subsection}/gallery/${image_group.link_text}`
									);
									set_show_gallery(true);
									set_gallery_item(image_group.link_text);
								}}
								aria-label={`View image gallery.`}
							/>
						);
					}
					return (
						<Link
							to={`/documentation/${section}/${subsection}/gallery/${image_group.link_text}`}
							id={`${section}-${subsection}-${image_group.link_text}`}
							key={`${section}-${subsection}-${image_group.link_text}`}
							dangerouslySetInnerHTML={{ __html: image_group.preview_markup }}
							onClick={(event) => {
								event.preventDefault();
								window.history.replaceState(
									{},
									"",
									`/documentation/${section}/${subsection}/gallery/${image_group.link_text}`
								);
								set_show_gallery(true);
								set_gallery_item(image_group.link_text);
							}}
							aria-label={`Gallery image: ${
								image_group.caption ? image_group.caption : "No caption provided for this image."
							}`}
						/>
					);
				})}
			</div>
		</section>
	);
}
