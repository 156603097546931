import React from 'react'
import { NavLink } from 'react-router-dom'

export function LoginRegisterSwitch() {
	return (
		<div className="login_register_switch">
			<NavLink
				to="/login"
				className={({ isActive }) => {
					return isActive ? "active" : "";
				}}
			>
				Login
			</NavLink>
			<NavLink
				to="/register"
				className={({ isActive }) => {
					return isActive ? "active" : "";
				}}
			>
				Register
			</NavLink>
		</div>
	);
}
