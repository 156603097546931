import React from 'react'
import { redirectUnauthenticatedToLoginAndReturnAfter } from "../../models/User";
import { CommonPageHeaderWithSearch } from "../../components/CommonPageHeaderWithSearch";
import { Checklist } from "../../components/Checklist";
import { get_checklist_search_results } from "../../models/Search";
import { Key, useState,useEffect } from "react";

import { metaDataT } from "../../types/GlobeType";
import {useNavigate} from "react-router-dom";
import DocumentMeta from "react-document-meta";
import Loader from "../../components/Loader";


export let meta = (data: any) => {
	let meta_object: metaDataT = {};
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};

const getData = (token: string) => {
	return fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_checklists`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then(async (response) => {
			if (response.status !== 200) {
				throw JSON.stringify({ status: response.status, response });
			}
			return response;
		})
		.then((response) => response.json());
}

// @ts-ignore
export default function Checklists({ user, token }: any) {
	const navigate = useNavigate();
	let [isLoading, setIsLoading] = useState(true);

	const [checklists_data, setChecklistsData] = useState({ checklists: [], seo: {} });
	useEffect(() => {
		if (token) {
			getData(token).then(async (resp) => {
				setChecklistsData(resp)
				setIsLoading(false)
			})
		} else {
			// eslint-disable-next-line no-restricted-globals
			redirectUnauthenticatedToLoginAndReturnAfter(location.pathname, user, navigate);
		}
	}, [token]);

	let { checklists, seo }: any = checklists_data;
	// TODO: fix
	let search_params = {
		search: "",
	};
	let [search_state, set_search_state] = useState(search_params.search ?? "");

	if (search_state !== "") {
		checklists = get_checklist_search_results(checklists, search_state);
	}

	if (isLoading) {
		return <Loader />
	}

	return (
		<DocumentMeta {...meta(checklists_data)}>
			<main className="checklists_page">
				<CommonPageHeaderWithSearch
					title="Checklists"
					search="Search checklists"
					search_value={search_state}
					set_search_state={set_search_state}
				/>
				<div className="site_container padding checklists_content">
					{checklists.length>0 ? (
						checklists.map((checklist: { id: Key | null | undefined; title: any; icon: any; slug: any; }) => {
								return <Checklist key={checklist.id} title={checklist.title} icon={checklist.icon} slug={checklist.slug} />;
							})
					) : (
						<div>
							<p>
								No results were found for that search term.
							</p>
							<p>
								Please try searching for something else.
							</p>
						</div>

					)}
				</div>
			</main>
		</DocumentMeta>
	);
}
