import React from 'react'
import {Link, useRoutes} from 'react-router-dom'
import { Cross, PrevSlider, NextSlider } from "../svg";

export function Gallery({
	images,
	section,
	subsection,
	show_this_gallery,
	set_show_gallery,
	gallery_item,
	set_gallery_item,
}: {
	images: GalleryItem[];
	section: string;
	subsection: string;
	show_this_gallery: boolean;
	set_show_gallery: CallableFunction;
	gallery_item?: string;
	set_gallery_item: CallableFunction;
}) {
	// TODO: fix
	let [search_params] = [''];
	const match_current_image = (image_group: GalleryItem) => image_group.link_text === gallery_item;
	let current_image_group = images.find(match_current_image) ?? images[0];
	let current_image_group_index = images.findIndex(match_current_image) ?? 0;
	let prev_image_index = current_image_group_index - 1 >= 0 ? current_image_group_index - 1 : images.length - 1;
	let next_image_index = current_image_group_index + 1 < images.length ? current_image_group_index + 1 : 0;

	if(images[next_image_index].image_id ===0) {
		next_image_index = next_image_index + 1 < images.length ? next_image_index + 1 : 0;
	}

	function close_popup(event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) {
		event.preventDefault();
		set_show_gallery(false);
		window.history.replaceState(
			{},
			"",
			`/documentation/${section}/${subsection}${
				search_params.toString().length > 0 ? `?${search_params.toString()}` : ""
			}`
		);
	}
	return (
		<>
			<div className={`gallery_pop_up${show_this_gallery ? " show" : ""}`}>
				<Link
					to={`/documentation/${section}/${subsection}`}
					className="close"
					aria-label="Close gallery pop-up."
					onClick={(event) => close_popup(event)}
				>
					<Cross />
				</Link>
				<figure className="gallery_image">
					<div className="image_wrapper" dangerouslySetInnerHTML={{ __html: current_image_group.markup }}></div>
					{current_image_group.caption ? <figcaption>{current_image_group.caption}</figcaption> : null}
				</figure>
				<Link
					to={`/documentation/${section}/${subsection}/gallery/${images[prev_image_index].link_text}`}
					className="previous_image"
					aria-label="Go to previous image."
					onClick={(event) => {
						event.preventDefault();
						window.history.replaceState(
							{},
							"",
							`/documentation/${section}/${subsection}/gallery/${images[prev_image_index].link_text}`
						);
						set_gallery_item(images[prev_image_index].link_text);
					}}
				>
					<PrevSlider />
				</Link>
				<Link
					to={`/documentation/${section}/${subsection}/gallery/${images[next_image_index].link_text}`}
					className="next_image"
					aria-label="Go to next image."
					onClick={(event) => {
						event.preventDefault();
						window.history.replaceState(
							{},
							"",
							`/documentation/${section}/${subsection}/gallery/${images[next_image_index].link_text}`
						);
						set_gallery_item(images[next_image_index].link_text);
					}}
				>
					<NextSlider />
				</Link>
			</div>
			<div className="gallery_backdrop" onClick={(event) => close_popup(event)}></div>
		</>
	);
}
