import React, {useState} from 'react'
import  { useNavigate, Link   } from 'react-router-dom'
import {
	Input,
	PasswordInput,
	SelectInput,
	Submit,
	AcceptanceCheckbox,
} from "../components/FormInputs";
import { LoginRegisterSwitch } from "../components/LoginRegisterSwitch";
import { Lock } from "../svg";
import { Validate, validateRegisterForm } from "../models/Validate";
import { organisation_role_options } from "../models/Data";
import { authenticator } from "../services/auth";
import {
	metaDataT,
} from "../types/GlobeType";
// @ts-ignore
import { useLoaderData } from "../hooks/useLoaderData";
import DocumentMeta from "react-document-meta";

export let meta = (data: any) => {
	let meta_object: metaDataT = {};
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};


// @ts-ignore
export let action = async (event:any,navigate) => {
	const form_data =  new FormData(event.target);
	const errors = validateRegisterForm(form_data);
	if (errors.exist) {
		return { errors, values: Object.fromEntries(form_data) };
	}
	const RegisterRequestData = new URLSearchParams();
	RegisterRequestData.set("AUTH_KEY", "FFR_REGISTER_USER_43J89VSY2");
	RegisterRequestData.set("user_login", form_data.get("email_address") as string);
	RegisterRequestData.set("email", form_data.get("email_address") as string);
	RegisterRequestData.set("password", form_data.get("password") as string);
	RegisterRequestData.set("first_name", form_data.get("first_name") as string);
	RegisterRequestData.set("last_name", form_data.get("last_name") as string);
	RegisterRequestData.set(
		"user_meta",
		JSON.stringify({
			organisation: form_data.get("organisation") as string,
			organisation_role: form_data.get("organisation_role") as string,
		})
	);

	const register_user = await fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}simple-jwt-login/v1/users`, {
		method: "POST",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
		body: RegisterRequestData,
	}).then((response) => response.json());
	if (register_user.success === true) {
		const formData = new FormData()
		formData.set('username_or_email', form_data.get("email_address") as string)
		formData.set('password', form_data.get("password") as string)
		await authenticator.authenticate(formData, {
			failureRedirect: "/login",
		});
		// eslint-disable-next-line no-restricted-globals
		location.replace("/?register_success");
		return null
	} else {
		if (register_user?.data?.message) {
			return {
				errors: {
					registering_user: register_user.data.message,
				},
				values: Object.fromEntries(form_data),
			};
		}
		return {
			errors: {
				registering_user: `The site failed to register your user. Please refresh or try again later.`,
			},
			values: Object.fromEntries(form_data),
		};
	}
};


// @ts-ignore
export default function Register() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false)
	const [action_data, setActionData] = useState<{ errors?: {[key: string]: string}, values?: {[key: string]: any}}>({})
	// eslint-disable-next-line no-restricted-globals
	const [seoData] = useLoaderData(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_post_by_url/${location.pathname}`, {})

	const handleOnSubmit = async (event:any) => {
		setLoading(true)
		event.preventDefault();
		// @ts-ignore
		setActionData(await action(event,navigate));
		setLoading(false)
	}

	return (
		<DocumentMeta {...meta(seoData)}>
			<h1 className="screen_reader_only">Register</h1>
			<LoginRegisterSwitch />
			<form method="post" className="register_form" noValidate  onSubmit={handleOnSubmit}>
				<h2>Account details</h2>
				<Input
					name="email_address"
					label="Email address"
					type="email"
					required={true}
					className="single_line_input email_field"
					id="email_field"
					error={action_data?.errors?.email_address}
					isInvalid={Validate.is_email_field_invalid}
					defaultValue={action_data?.values?.email_address}
					autoComplete="email"
				/>
				<PasswordInput
					name="password"
					label="Password"
					required={true}
					new_password={true}
					className="single_line_input password_field"
					id="password_field"
					leadingIcon={<Lock />}
					error={action_data?.errors?.password}
					isInvalid={Validate.is_register_password_field_invalid}
					defaultValue={action_data?.values?.password}
					autoComplete="new-password"
				/>
				<hr />
				<h2>Your details</h2>
				<Input
					name="first_name"
					label="First name"
					type="text"
					required={true}
					className="single_line_input first_name_field"
					id="first_name_field"
					error={action_data?.errors?.first_name}
					isInvalid={Validate.is_first_name_field_invalid}
					defaultValue={action_data?.values?.first_name}
					autoComplete="given-name"
				/>
				<Input
					name="last_name"
					label="Last name"
					type="text"
					required={true}
					className="single_line_input last_name_field"
					id="last_name_field"
					error={action_data?.errors?.last_name}
					isInvalid={Validate.is_last_name_field_invalid}
					defaultValue={action_data?.values?.last_name}
					autoComplete="family-name"
				/>
				<Input
					name="organisation"
					label="Organisation name"
					type="text"
					required={true}
					className="single_line_input organisation_field"
					id="organisation_field"
					error={action_data?.errors?.organisation}
					isInvalid={Validate.is_organisation_field_invalid}
					defaultValue={action_data?.values?.organisation}
					autoComplete="organization"
				/>
				<SelectInput
					name="organisation_role"
					label="Organisation role"
					required={true}
					className="select_input organisation_role_field"
					id="organisation_role_field"
					options={organisation_role_options}
					error={action_data?.errors?.organisation_role}
					isInvalid={Validate.is_organisation_role_field_invalid}
					defaultValue={action_data?.values?.organisation_role}
				/>
				<hr />
				<h2>Site terms</h2>
				<AcceptanceCheckbox
					label="Data privacy"
					className="data_privacy_checkbox"
					error={action_data?.errors?.data_privacy_group}
					name="data_privacy"
					required={true}
					id="data_privacy_checkbox"
					isInvalid={Validate.is_data_privacy_checkbox_invalid}
					checkboxLabel={`The personal data you provide is used to answer queries, process orders or allow access to specific information. You have the right to modify and delete all the personal information found in the "My Account" page.`}
					defaultChecked={action_data?.values?.data_privacy}
				/>
				<AcceptanceCheckbox
					label="Sign up for our newsletter"
					className="newsletter_signup_checkbox"
					error={action_data?.errors?.newsletter_signup_group}
					name="newsletter_signup"
					required={false}
					id="newsletter_signup_checkbox"
					isInvalid={() => false}
					checkboxLabel={`You may unsubscribe at any moment. For that purpose, please find our contact info in the legal notice.`}
					defaultChecked={action_data?.values?.newsletter_signup}
				/>
				<AcceptanceCheckbox
					label="Terms and conditions"
					className="terms_checkbox"
					error={action_data?.errors?.terms_group}
					name="terms"
					required={true}
					id="terms_checkbox"
					isInvalid={Validate.is_terms_checkbox_invalid}
					checkboxLabel={
						<>
							I agree to the <Link to="/terms-and-conditions">Terms and Conditions</Link> and the{" "}
							<Link to="/privacy-policy">Privacy Policy</Link>.
						</>
					}
					defaultChecked={action_data?.values?.terms}
				/>
				{action_data?.errors?.registering_user ? (
					<p className="validation_errors">{action_data.errors?.registering_user}</p>
				) : action_data?.errors?.exist ? (
					<p className="validation_errors">
						There are one or more validation errors above, you will need to fix these before you create an account.
					</p>
				) : null}
				<Submit className="button register_submit">
					{loading ? "Registering..." : "Register"}
				</Submit>
			</form>
		</DocumentMeta>
	);
}
