import  { useNavigate } from 'react-router-dom';
import React from "react";
import {metaDataT, SeoDataT} from "../types/GlobeType";
import Loader from "../components/Loader";

export let meta = ({ data }: { data: SeoDataT }) => {
	let meta_object: metaDataT = {};
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};

// @ts-ignore

const wpLogout = (token: any) => {
	let form_data = new URLSearchParams();
	form_data.set("jwt", token);
	fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}simple-jwt-login/v1/auth/revoke`, {
		method: "POST",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
		body: form_data,
	}).catch(() => {});
}

const Logout = ({token}: { token: string }) => {
	if (token) {
		wpLogout(token)
		localStorage.clear();
		sessionStorage.clear();
	}
	// eslint-disable-next-line no-restricted-globals
	location.replace("/");
	return <Loader />
}

export default Logout


