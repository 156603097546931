// @ts-ignore
import JWT from "@tsndr/cloudflare-worker-jwt";
import {User} from "../types/GlobeType";

interface JWTAuthResponse {
	success: boolean;
	data: {
		jwt: string;
	};
}

interface JWTPayload {
	iat: number;
	id: number;
}

async function validate_JWT_and_payload(jwt: string) {
	const JWTIsValid = await JWT.verify(jwt, process.env.REACT_APP_JWT_SECRET as string);
	if (!JWTIsValid) {
		throw new Error("JWT verification failed.");
	}
	const payload = JWT.decode(jwt) as unknown as JWTPayload | null;
	if (!payload) {
		throw new Error("Missing user data.");
	}
	return {
		jwt,
		payload,
	};
}

export async function check_login_and_return_user(username_or_email: string, password: string): Promise<AuthUser> {
	return new Promise(async (resolve, reject) => {

		let form_data = new URLSearchParams();
		form_data.set("username_or_email", username_or_email);
		const user_login_field_type: { field_type: "username" | "email" | false } = await fetch(
			`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/user_login_field_type/`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: form_data,
			}
		)
			.then((response) => response.json())
			.catch((error) => {
				sessionStorage.setItem('auth:error', error.message)
				return reject(error.message);
			});
		if (!user_login_field_type || user_login_field_type.field_type === false) {
			sessionStorage.setItem('auth:error', "Login not found, please make sure you typed it correctly.")
			return reject("Login not found, please make sure you typed it correctly.");
		}
		form_data = new URLSearchParams();
		form_data.set(user_login_field_type.field_type, username_or_email);
		form_data.set("password", password);
		form_data.set("AUTH_KEY", "FFR_LOGIN_FNREU83NS");
		const JWTAuthResponse: JWTAuthResponse = await fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}simple-jwt-login/v1/auth`, {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: form_data,
		})
			.then((response) => response.json())
			.catch((error) => {
				sessionStorage.setItem('auth:error', error.message)
				return reject(error.message);
			});
		if (JWTAuthResponse.success !== true) {
			sessionStorage.setItem('auth:error', "There was an error logging you in. Please refresh and retry. If you continue having problems, let us know.")
			return reject(
				"There was an error logging you in. Please refresh and retry. If you continue having problems, let us know."
			);
		}
		const valid_jwt = await validate_JWT_and_payload(JWTAuthResponse.data.jwt);

		let user: AuthUser = {
			jwt: valid_jwt.jwt,
			ID: valid_jwt.payload.id,
		};
		sessionStorage.setItem('auth:error', "")
		sessionStorage.setItem('user', JSON.stringify(user))
		resolve(user);
	})
}

export async function verify_registered_user(username: FormDataEntryValue | null, password: FormDataEntryValue | null) {
	let form_data = new URLSearchParams();
	// @ts-ignore
	form_data.set("username", username);
	// @ts-ignore
	form_data.set("password", password);
	const JWTAuthResponse: JWTAuthResponse = await fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}simple-jwt-login/v1/auth`, {
		method: "POST",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
		body: form_data,
	})
		.then((response) => response.json())
		.catch((error) => {
			throw new Error(error.message);
		});

	if (!JWTAuthResponse.success) {
		throw new Error(
			"There was an error logging you in. Please refresh and retry. If you continue having problems, let us know."
		);
	}
	const valid_jwt = await validate_JWT_and_payload(JWTAuthResponse.data.jwt);

	let user: AuthUser = {
		jwt: valid_jwt.jwt,
		ID: valid_jwt.payload.id,
	};
	sessionStorage.setItem('user', JSON.stringify(user))
	return user;
}
export function redirectUnauthenticatedToLoginAndReturnAfter(return_url: string, authUser: false | User | undefined, navigate: any) {
	let new_params = new URLSearchParams();
	new_params.set("redirect_after_login", return_url);
	if (!authUser) {
		return navigate(`/login?${new_params.toString()}`);
	}
}
export async function is_user_authenticated(): Promise<false | AuthUser> {
	return new Promise(async (resolve, reject) => {
		let auth_user = JSON.parse(sessionStorage.getItem('user') || '{}')
		if (!auth_user || !auth_user.jwt) {
			return reject(false);
		}
		const JWTIsValid = await JWT.verify(auth_user.jwt, process.env.REACT_APP_JWT_SECRET as string);
		if (JWTIsValid) {
			return resolve(auth_user.jwt)
		}
		/**
		 * JWT expires after 60 minutes, so this ensures if the user has an
		 * authenticated session that the JWT is valid. The time to refresh
		 * for the JWT is as long as the remember me for the session, so there
		 * won't be a case where the JWT can't be validated.
		 */

		const new_jwt = await fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}simple-jwt-login/v1/auth/refresh?JWT=${auth_user.jwt}`, {
			method: "POST",
			mode: "no-cors"
		})
			.then((response) => {
				return response.json()
			})
			.then((response) => {
				if (response.success !== true) {
					throw new Error();
				}
				return response;
			})
			.then(async (response) => {
				const JWTIsValid = await JWT.verify(response.data.jwt, process.env.REACT_APP_JWT_SECRET as string);
				if (!JWTIsValid) {
					throw new Error();
				}
				sessionStorage.setItem('user', JSON.stringify(response.data));
				return resolve(response.data.jwt);
			})
			.catch((e) => {
				sessionStorage.setItem('user', '{}');
				return reject(false)
			});
	})
}
async function get_user_from_WP(JWT: string) {
	return fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}wp/v2/users/me?context=edit`, {
		credentials: 'include',
		headers: {
			Authorization: `Bearer ${JWT}`,
		},
	})
		.then((response) => response.json())
		.then(async (response: any) => {
			let user: User = {
				email: response.email,
				username: response.username,
				first_name: response.first_name ?? "",
				last_name: response.last_name ?? "",
				meta: {
					organisation: response.meta.organisation ?? "",
					organisation_role: response.meta.organisation_role ?? "",
					sections_found_helpful: response.meta.sections_found_helpful ?? [],
					sections_found_unhelpful: response.meta.sections_found_unhelpful ?? [],
					favourite_sections: response.meta.favourite_sections ?? [],
					saved_checklists: response.meta.saved_checklists ?? [],
				},
			};
			return user;
		})
		.catch((error) => {
			console.log(error);
			throw new Error("Missing user!");
		});
}
export async function get_user(auth_user: AuthUser) {
	if (auth_user && typeof auth_user.jwt === 'string') {
		return get_user_from_WP(auth_user.jwt);
	}
	return Promise.reject(new Error('Missing token!'))
}

export async function actually_update_current_user(fields_to_update: UserUpdate, token: string) {
	let form_data = new URLSearchParams();
	if (fields_to_update.email) {
		form_data.set("email", fields_to_update.email);
	}
	if (fields_to_update.password) {
		form_data.set("password", fields_to_update.password);
	}
	if (fields_to_update.first_name) {
		form_data.set("first_name", fields_to_update.first_name);
	}
	if (fields_to_update.last_name) {
		form_data.set("last_name", fields_to_update.last_name);
	}
	if (fields_to_update.meta) {
		form_data.set("meta", JSON.stringify(fields_to_update.meta));
	}

	let updated_user = await fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/update_user_information/me`, {
		method: "POST",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: `Bearer ${token}`,
		},
		body: form_data,
	})
		.then((response) => {
			return response.json()
		})
		.catch((error) => {
			console.log({ error });
			return false;
		});
	if (updated_user.success) {
		let user = await get_user_from_WP(token);
		return user;
	}
	throw new Error("Failed to update user. Please refresh and try again.");
}
export async function update_current_user(user: User, fields_to_update: UserUpdate, token: string) {
	let fields_to_actually_update: UserUpdate = {};
	// TODO: Refactor this mess.
	if (fields_to_update.email && user.email !== fields_to_update.email) {
		fields_to_actually_update.email = fields_to_update.email;
	}
	// @ts-ignore
	if (fields_to_update.password && user.password !== fields_to_update.password) {
		fields_to_actually_update.password = fields_to_update.password;
	}
	if (fields_to_update.first_name && user.first_name !== fields_to_update.first_name) {
		fields_to_actually_update.first_name = fields_to_update.first_name;
	}
	if (fields_to_update.last_name && user.last_name !== fields_to_update.last_name) {
		fields_to_actually_update.last_name = fields_to_update.last_name;
	}
	if (fields_to_update.meta && user.meta !== fields_to_update.meta && Object.keys(fields_to_update.meta).length > 0) {
		fields_to_actually_update.meta = {};
		if (fields_to_update.meta.organisation && user.meta.organisation !== fields_to_update.meta.organisation) {
			fields_to_actually_update.meta.organisation = fields_to_update.meta.organisation;
		}
		if (
			fields_to_update.meta.organisation_role &&
			user.meta.organisation_role !== fields_to_update.meta.organisation_role
		) {
			fields_to_actually_update.meta.organisation_role = fields_to_update.meta.organisation_role;
		}
		if (
			fields_to_update.meta.sections_found_helpful &&
			user.meta.sections_found_helpful !== fields_to_update.meta.sections_found_helpful
		) {
			fields_to_actually_update.meta.sections_found_helpful = fields_to_update.meta.sections_found_helpful;
		}
		if (
			fields_to_update.meta.sections_found_unhelpful &&
			user.meta.sections_found_unhelpful !== fields_to_update.meta.sections_found_unhelpful
		) {
			fields_to_actually_update.meta.sections_found_unhelpful = fields_to_update.meta.sections_found_unhelpful;
		}
		if (
			fields_to_update.meta.favourite_sections &&
			user.meta.favourite_sections !== fields_to_update.meta.favourite_sections
		) {
			fields_to_actually_update.meta.favourite_sections = fields_to_update.meta.favourite_sections;
		}
		if (
			fields_to_update.meta.saved_checklists &&
			user.meta.saved_checklists !== fields_to_update.meta.saved_checklists
		) {
			fields_to_actually_update.meta.saved_checklists = fields_to_update.meta.saved_checklists;
		}
	}

	if (Object.keys(fields_to_actually_update).length === 0) {
		return;
	}
	return await actually_update_current_user(fields_to_actually_update, token);
}
