import React from 'react'
import { Link } from 'react-router-dom'

export function Checklist({
	title,
	icon,
	slug,
}: {
	title: ArchiveChecklistData["title"];
	icon: ArchiveChecklistData["icon"];
	slug: ArchiveChecklistData["slug"];
}) {
	return (
		<Link to={`/checklists/${slug}`} className="checklist">
			<figure className="checklist_icon" dangerouslySetInnerHTML={{ __html: icon }}></figure>
			<h2 className="checklist_title">{title}</h2>
		</Link>
	);
}
