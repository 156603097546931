import React, {useEffect, useState} from "react";
import {useNavigate, Link} from 'react-router-dom'
import { authenticator } from "../services/auth";
import { Input, PasswordInput, Submit, CheckboxInput } from "../components/FormInputs";
import { LoginRegisterSwitch } from "../components/LoginRegisterSwitch";
import { User, Lock } from "../svg";
// @ts-ignore
import { is_user_authenticated } from "../models/User";
import { metaDataT } from "../types/GlobeType";
import DocumentMeta from "react-document-meta";
import {useLoaderData} from "../hooks/useLoaderData";


export let meta = (data: any) => {
	let meta_object: metaDataT = {};
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};

function is_empty(field: string) {
	if (field === "") {
		return "This field is required.";
	}
	return false;
}

// @ts-ignore
const Login = ({ token }: { token: string }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false)
	const [auth_errors, setAuth_errors] = useState<{ error?: { message: string } }>({})
	// eslint-disable-next-line no-restricted-globals
	const [seoData] = useLoaderData(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_post_by_url/${location.pathname}`, {})

	useEffect(() => {
		if (token) {
			navigate("/my-account/details");
		}
	}, [token]);

	const handleOnSubmit = async (event:any) => {
		event.preventDefault()
		setLoading(true)
		// action({ request });
		const formData = new FormData(event.target);
        const redirectUrl = formData.get('redirect_after_login') as string ?? "/";
		authenticator.authenticate(formData, {
			failureRedirect: "/login",
		}).then((loginData) => {
			setLoading(false)
			if(loginData && loginData.jwt) {
				// eslint-disable-next-line no-restricted-globals
				location.replace(redirectUrl);
			}
			else {
				navigate("/login");
			}
		}).catch((e) => {
			setAuth_errors({ error: { message: e }});
			setLoading(false)
		})
	}
	// eslint-disable-next-line no-restricted-globals
	let redirect_after_login = new URLSearchParams(location.search).get('redirect_after_login') ?? false;
	return (
		<DocumentMeta {...meta(seoData)}>
			<h1 className="screen_reader_only">Login</h1>
			<LoginRegisterSwitch />
			<form method="post" className="login_form" onSubmit={handleOnSubmit}>
				{auth_errors.error ? <p className="login_error_message">{auth_errors.error?.message}</p> : null}
				<Input
					name="username_or_email"
					label="Email address"
					required={true}
					className="single_line_input username_or_email_field"
					id="username_or_email_field"
					leadingIcon={<User />}
					isInvalid={is_empty}
					autoComplete="username"
				/>
				<PasswordInput
					name="password"
					label="Password"
					required={true}
					new_password={false}
					className="single_line_input password_field"
					id="password_field"
					leadingIcon={<Lock />}
					isInvalid={is_empty}
					autoComplete="current-password"
				/>
				<Link className="forgotten_password_link" to="/forgotten-password">
					Forgotten your password?
				</Link>
				<CheckboxInput
					name="remember_me"
					id="remember_me_checkbox"
					className="remember_me_checkbox"
					label="Remember me"
				/>
				{redirect_after_login ? <input type="hidden" name="redirect_after_login" value={redirect_after_login} /> : null}
				<Submit className="button login_submit">{loading ? "Logging in..." : "Login"}</Submit>
			</form>
			<p className="register_note">
				Don't have an account yet? <Link to="/register">Register now</Link>.
			</p>
		</DocumentMeta>
	);
}

export default Login
