import React, { useState } from "react";
import { useParams } from 'react-router-dom'

import { CommonPageHeaderWithSearch } from "../../components/CommonPageHeaderWithSearch";
import { get_support_accordions_search_results } from "../../models/Search";
import type { metaDataT } from '../../types/GlobeType'
import type { AccordionT } from '../../types/SuportType'

import {useLoaderData} from "../../hooks/useLoaderData";
import DocumentMeta from "react-document-meta";
import Loader from "../../components/Loader";

export let meta = (data: any) => {
	let meta_object: metaDataT = {}
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};


// @ts-ignore
export default function Support({ user, token }:any) {
	const { slug } = useParams()
	let [supportData, isLoading] = useLoaderData(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_support_data/${slug}`, {
		title: "",
		search: "",
		accordions: []
	},{
		Authorization: `Bearer ${token}`
	});

	// @ts-ignore
	let { title, search, accordions } = supportData;
	// @ts-ignore
	let [search_params] = [{
		get: () => ''
	}];
	let [search_state, set_search_state] = useState( "");

	if (search_state !== "") {
		accordions = get_support_accordions_search_results(accordions, search_state);
	}

	if (isLoading) {
		return <Loader />
	}

	return (
		<DocumentMeta {...meta(supportData)}>
			<main className="support_page">
				<CommonPageHeaderWithSearch
					title={title}
					search={search}
					search_value={search_state}
					set_search_state={set_search_state}
				/>
				<div className="site_container padding support_page_content">
					{accordions.length > 0 ? (
						accordions.map((accordion: AccordionT ) => {
							return (
								<details key={accordion.title} className="wp-block-custom accordion">
									<summary className="wp-block-custom accordion-toggle">
										<div className="accordion_header_wrap">
											<h2 dangerouslySetInnerHTML={{ __html: accordion.title }}></h2>
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22">
												<rect
													width="1.36"
													height="19.89"
													x="20.95"
													y="10.32"
													fill="currentColor"
													stroke="currentColor"
													strokeWidth=".13"
													rx=".68"
													transform="rotate(90 20.95 10.32)"
												/>
												<rect
													width="1.36"
													height="19.89"
													x="10.32"
													y="1.06"
													fill="currentColor"
													stroke="currentColor"
													strokeWidth=".13"
													rx=".68"
												/>
											</svg>
										</div>
									</summary>
									<div
										className="wp-block-custom accordion-content"
										dangerouslySetInnerHTML={{ __html: accordion.content }}
									></div>
								</details>
							);
						})
					) : (
						<div className="no_results">
							{search_state !== "" ? (
								<p>No results were found for that search term. Please try searching for something else.</p>
							) : (
								<p>There was an error loading these posts. Please try again later.</p>
							)}
						</div>
					)}
				</div>
			</main>
		</DocumentMeta>
	);
}
