import {useHref, useNavigate, useParams} from "react-router-dom";
import { GalleryPreview } from "./GalleryPreview";
import { Gallery } from "./Gallery";
import { AddToFavourites } from "../routes/documentation/add-favourite";
import { Print, Download, Share } from "../svg";
import QRCode from "react-qr-code";
import React, { useState } from "react";
import {User} from "../types/GlobeType";

export function SubSection({
	subsection_id,
	className,
	id,
	subsection_data,
	section,
	subsection,
	user,
    token,
}: {
	subsection_id: number;
	className: string;
	id: string;
	subsection_data: SubsectionData;
	section: string;
	subsection: string;
	user: false | User | undefined;
	token: string;
}) {
	const navigate = useNavigate();
	let docs_params = useParams();
	let show_any_gallery = docs_params.gallery_or_print === "gallery" && docs_params.gallery_item !== undefined;
	let [show_gallery, set_show_gallery] = useState(
		show_any_gallery && docs_params.section === section && docs_params.subsection === subsection
	);
	let [gallery_item, set_gallery_item] = useState(docs_params.gallery_item);
	let href = useHref(`/documentation/${section}/${subsection}`);
    // eslint-disable-next-line no-restricted-globals
    let permalink = `${location.origin}${href}`;

	let share_data = {
		title: subsection_data.post_title,
		url: permalink,
	};

    async function share_section_via_share_api() {
		try {
			await navigator.share(share_data);
		} catch (error) {
			console.error(error);
			let error_message = document.getElementById(id)?.querySelector("section_action_error");
			if (error_message) {
				error_message.innerHTML = "There was an error sharing this section: " + error;
			}
		}
	}

	const handleClick = (e: any) => {
		if (e.target?.href && e.target?.href.includes('/gallery/')) {
			e.preventDefault()
			const linkText = e.target?.href.split('/gallery/')[1]
			window.history.replaceState(
				{},
				"",
				`/documentation/${section}/${subsection}/gallery/${linkText}`
			);
			set_show_gallery(true);
			set_gallery_item(linkText);
		}
	}

	return (
		<section id={id} className={`docs_subsection_content${className.length > 0 ? ` ${className}` : ""}`}>
			<header className="content_header">
				<p className="subsection_number">{subsection_data.sub_section_number}</p>
				<h3 className="subsection_title" dangerouslySetInnerHTML={{ __html: subsection_data.post_title }}></h3>
                <div className="qrcode">
                    <QRCode value={permalink} size={96} />
                </div>
			</header>
			<hr className="docs_separator" />
			<aside className="content_sidebar">
				{subsection_data.gallery.length > 0 ? (
					<>
						<GalleryPreview
							section={section}
							subsection={subsection}
							images={subsection_data.gallery}
							set_show_gallery={set_show_gallery}
							set_gallery_item={set_gallery_item}
						/>
						<Gallery
							show_this_gallery={show_gallery}
							set_show_gallery={set_show_gallery}
							section={section}
							subsection={subsection}
							images={subsection_data.gallery}
							gallery_item={gallery_item}
							set_gallery_item={set_gallery_item}
						/>
						<hr className="docs_separator" />
					</>
				) : null}
			</aside>
			<div
				onClick={handleClick}
				className="content"
				dangerouslySetInnerHTML={{
					__html: subsection_data.post_content_rendered
						.replaceAll(
							/<a ([^>]*)data-link_type="internal"([^>]*)>([^<]*)<\/a>/g,
							`<a $1 data-link_type="internal" $2 class="in_text_local_link in_text_local_link_${subsection_id}">$3</a>`
						)
						.replaceAll(
							/<a ([^>]*)href="#gallery-([A-Za-z0-9-_]*)"([^>]*)>([^<]*)<\/a>/g,
							`<a $1 href="/documentation/${section}/${subsection}/gallery/$2"$3 class="in_text_gallery_link in_text_gallery_link_${subsection_id}">$4</a>`
						),
				}}
			></div>
			<div className="footer_text">© Reuse-Network, {new Date().getFullYear()}</div>
			<hr className="docs_separator" />
			<footer className="section_actions">
				<div className="section_quick_actions">
					<AddToFavourites id={subsection_id} user={user} token={token}/>
					<button
						onClick={() => {
                            navigate(`/documentation/${section}/${subsection}/print`)
						}}
						className="section_quick_action_button"
						aria-label="Print this section."
					>
						<Print />
					</button>
					{subsection_data.download_file ? (
						<a
							href={subsection_data.download_file}
							className="section_quick_action_button"
							target="_blank"
							aria-label="Download this section."
							download rel="noreferrer"
						>
							<Download />
						</a>
					) : null}
					<button
						className="section_quick_action_button"
						onClick={share_section_via_share_api}
						aria-label="Share this section."
					>
						<Share />
					</button>
					<p className="section_action_error"></p>
				</div>
			</footer>
			<hr className="docs_separator" />
		</section>
	);
}
