import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { Gears } from "../svg";

export function NeedHelp() {
	const [isShownOnMobile, setIsShownOnMobile] = useState(false);
	return (
		<div className="need_help_section">
			<button className="need_help_button" onClick={() => setIsShownOnMobile(!isShownOnMobile)}>
				<span className="screen_reader_only">Need help</span>?
			</button>
			<div className={`need_help_banner${isShownOnMobile ? " show" : ""}`}>
				<strong className="need_help_title">Need help?</strong>
				<Link to="/support/faq" className="need_help_link">
					Go to the support centre
				</Link>
				<Gears className="need_help_icon" />
			</div>
		</div>
	);
}
