import React, {useEffect, useState} from "react";
import { is_user_authenticated, get_user, update_current_user } from "../../models/User";
import { Check, Checklist } from "../../svg";
import { User } from "../../types/GlobeType";

function save_or_unsave_checklist(user: User, checklist_id: number, token: string) {
	const before_update_user = JSON.parse(JSON.stringify(user));

	if (user.meta.saved_checklists.includes(checklist_id)) {
		user.meta.saved_checklists = user.meta.saved_checklists.filter(function (array_value: number) {
			return array_value !== checklist_id;
		});
	} else {
		user.meta.saved_checklists.push(checklist_id);
	}
	update_current_user(
		before_update_user,
		{
			meta: {
				saved_checklists: user.meta.saved_checklists,
			},
		},
		token
	);
	return user;
}

// @ts-ignore
export let action = async (checklist, token: string, user) => {
	if (checklist) {
		let checklist_id = Number(checklist);
		save_or_unsave_checklist(user, checklist_id, token);
	}
	return user;
};

export function SaveChecklist({ id, user, token }: { id: number; user: User; token: string }) {
	const [isSaved, setIsSaved] = useState(user?.meta?.saved_checklists?.includes(id));

	useEffect(() => {
		setIsSaved(user?.meta?.saved_checklists?.includes(id))
	}, [user, id])

	const handleOnSubmit = (event: any) => {
		event.preventDefault()
		action(id, token, user);
		setIsSaved(!isSaved);
	}

	return (
		<form method="post" action={`/checklists/save-checklist`} onSubmit={handleOnSubmit}>
			<input type="hidden" name="checklist_id" value={id} />
			<button
				type="submit"
				className={`button outline has_icon${isSaved ? " checked" : ""}`}
			>
				{isSaved ? (
					<>
						Unsave checklist
						<Check />
					</>
				) : (
					<>
						Save checklist
						<Checklist />
					</>
				)}
			</button>
		</form>
	);
}
