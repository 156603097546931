import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import DocumentMeta from 'react-document-meta'

import {Search, ArrowRight, Favourite, Check, Cross} from "../svg";
import {Input, Submit} from "../components/FormInputs";
import {FavouritedSection} from "../components/FavouritedSection";
import {MediaTextBlock} from "../components/MediaTextBlock";
import {HomeMediaTextBlock} from "../components/HomeMediaTextBlock";
import {metaDataT, SeoDataT} from "../types/GlobeType";
import {getFavouritedPosts, unfavourite_section} from "./my-account/favourites";
import {get_docs_sections_search_results} from "../models/Search";
import {getData} from "./documentation";
import {slugify} from "../models/Slugify";
import {pushSectionToHistory, scrollToNewSection} from "../models/DocsScroll";
import Loader from "../components/Loader";
import {validateMailchimpForm} from "../models/Validate";

interface WelcomeTextGroup {
    title: string;
    text: string;
    buttons: ButtonInfo[];
}

export let meta = (data: any) => {
    let meta_object: metaDataT = {};
    if (data?.seo?.title) {
        meta_object.title = data.seo.title;
    }
    if (data?.seo?.description) {
        meta_object.description = data.seo.description;
    }
    if (data?.seo?.robots) {
        meta_object.robots = Object.values(data.seo.robots).join(", ");
    }
    return meta_object;
};

function is_logged_in_content(
    home_content: any
): boolean {
    return home_content.user !== false;
}

// @ts-ignore
export default function Index({user, token}) {
    const navigate = useNavigate();
    let [isLoading, setIsLoading] = useState(true);
    let [selectedResultIndex, setSelectedResultIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [responseAPI, setResponseAPI] = useState<{ message: string, cssClass: string  }>()
    const handleOnSubmit = async (event:any) => {
        event.preventDefault()
        setLoading(true)
        const formData = new FormData(event.target);
        const errors = validateMailchimpForm(formData);
        if (errors.exist) {
            setLoading(false);

            return { errors, values: Object.fromEntries(formData) };
        }
        let form_data = new URLSearchParams();
        if(formData) {
            const email = formData.get('email_address')?.toString() ?? ""
            if(email) {
                form_data.set("email_address", email);
            }

        }
        const res = await fetch(
            `${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/mailchimp_signup`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: form_data,
            }
        )
            .then((response) => {
                setLoading(false)
                return response.json()
            })
            .catch((error) => {
                setLoading(false)
                return error.json()
            });
        event.target.reset();
        setResponseAPI({message: res.message, cssClass: res.status ===200 ? 'successMail': 'errorMail'  });

    }
    const [data, setData] = useState({
        network_that_cares_block: {
            image: '',
            title: '',
            text: '',
            buttons: []
        },
        checklists_block: {},
        favourited_posts: [{
            title: '',
            posts: [{
                id: 0,
                sub_section_number: '',
                section_title: '',
                post_title: '',
                last_updated: {
                    prefix: '',
                    date: '',
                    time: '',
                },
                post_content_rendered: '',
                gallery: [],
                docs_menu_info: [],
                download_file: ''
            }]
        }],
        register_cta_block: {
            image: '',
            title: '',
            text: '',
            list: [{
                list_item: ''
            }]
        },
        welcome_image: '',
        welcome_content: {
            title: '',
            text: '',
            buttons: []
        },
        docs_parents: [{
            slug: '',
            icon: '',
            title: ''
        }]
    })
    const [docsData, setDocsData] = useState<any>()
    let [search_state, set_search_state] = useState("");
    let search_sections: SubsectionData[] = get_docs_sections_search_results(docsData?.sections || [], search_state);

    function focusOnSearchItem(index: number) {
        let all_results: NodeListOf<HTMLElement> | undefined = document
            .getElementById("docs_search_results")
            ?.querySelectorAll("docs_search_result");
        if (all_results) {
            // @ts-ignore
            for (let result of all_results) {
                result.classList.remove("selected");
                if (result.dataset.result_index === index.toString()) {
                    result.classList.add("selected");
                }
            }
        }
    }

    useEffect(() => {
        if (token) {
            // @ts-ignore
            getData(token).then((docPostData) => {
                // @ts-ignore
                setDocsData(docPostData)
            })
        }
    }, [token]);

    useEffect(() => {
        search_sections = get_docs_sections_search_results(docsData?.sections, search_state);
        setSelectedResultIndex(0);
        focusOnSearchItem(0);
    }, [search_state]);

    useEffect(() => {
        const docs_postsReq = fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_docs_posts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async (response) => {
                if (response.status !== 200) {
                    throw JSON.stringify({status: response.status, response});
                }
                return response.json();
            })

        const home_contentReq = fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_home_content`, {}).then(
            (response) => {
                if (response.status !== 200) {
                    throw JSON.stringify({status: response.status, response});
                }
                return response.json();
            }
        )
        Promise.allSettled([home_contentReq, docs_postsReq]).then((results) => {
            setIsLoading(false)
            // @ts-ignore
            if (user && results[1] && results[1].value) {
                let resolved_user = user;
                let favourited_posts: DocsPostSection[] = [];
                // @ts-ignore
                let docs_sections = results[1].value.sections;
                for (let section of docs_sections) {
                    let docs_posts_data = section.posts;
                    docs_posts_data = docs_posts_data.filter((post: { id: any; }) => {
                        return resolved_user.meta.favourite_sections.includes(post.id);
                    });
                    if (docs_posts_data.length > 0) {
                        favourited_posts.push({
                            title: section.title,
                            posts: docs_posts_data,
                        });
                        if (favourited_posts.length > 8) {
                            break;
                        }
                    }
                }
                let welcome_content: WelcomeTextGroup;
                // @ts-ignore
                if (results.new_user) {
                    // @ts-ignore
                    welcome_content = results[0].value.welcome_block.new_user_text_group;
                } else {
                    // @ts-ignore
                    welcome_content = results[0].value.welcome_block.logged_in_text_group;
                }
                welcome_content.title = welcome_content.title
                    .replaceAll("%%USER_FIRSTNAME%%", resolved_user.first_name)
                    .replaceAll("%%USER_LASTNAME%%", resolved_user.last_name);
                welcome_content.text = welcome_content.text
                    .replaceAll("%%USER_FIRSTNAME%%", resolved_user.first_name)
                    .replaceAll("%%USER_LASTNAME%%", resolved_user.last_name);
                setData({
                    // @ts-ignore
                    seo: results[0].value.seo,
                    // @ts-ignore
                    welcome_image: results[0].value.welcome_block.image,
                    // @ts-ignore
                    welcome_content,
                    user: resolved_user,
                    // @ts-ignore
                    docs_parents: results[0].value.docs_parents,
                    // @ts-ignore
                    checklists_block: results[0].value.checklists_or_register_cta_block.checklists_block,
                    // @ts-ignore
                    network_that_cares_block: results[0].value.network_that_cares_block,
                    // @ts-ignore
                    favourited_posts,
                });
            } else {
                setData({
                    // @ts-ignore
                    seo: results[0].value.seo,
                    // @ts-ignore
                    welcome_image: results[0].value.welcome_block.image,
                    // @ts-ignore
                    welcome_content: results[0].value.welcome_block.logged_out_text_group,
                    // @ts-ignore
                    docs_parents: results[0].value.docs_parents,
                    // @ts-ignore
                    register_cta_block: results[0].value.checklists_or_register_cta_block.register_cta_block,
                    // @ts-ignore
                    network_that_cares_block: results[0].value.network_that_cares_block,
                    user: false,
                })
            }
        })
            .catch((error) => {
                console.error(error);
                throw new Error(error.message);
            });

    }, [user])

    const home_content = data;

    const handleRemove = (section_id: number) => {
        const lUser = unfavourite_section(user, section_id, token)
        // @ts-ignore
        setData({
            ...data,
            favourited_posts: getFavouritedPosts(data.favourited_posts, lUser)
        })
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <DocumentMeta {...meta(home_content)}>
            <main className="home_content">
                <HomeMediaTextBlock
                    content={{image: home_content.welcome_image, ...home_content.welcome_content}}
                    isMediaOnRight={true}
                    hasFullWidthImg={true}
                    className="welcome_section"
                />
                <section className="home_docs_section">
                    {is_logged_in_content(home_content) && (
                        <>
                            <h2 className="home_heading">Search documentation</h2>
                            <form method="get" onSubmit={(event) => event.preventDefault()}>
                                <Input
                                    autoComplete="off"
                                    className="single_line_input home_docs_search"
                                    id="docs_search"
                                    isInvalid={() => false}
                                    label={false}
                                    leadingIcon={<Search/>}
                                    name="search"
                                    onChange={(event) => set_search_state(event.target.value)}
                                    placeholder="Search documentation"
                                    trailingIcon={<ArrowRight/>}
                                    type="text"
                                />
                            </form>
                            <div
                                className={`docs_search_results${search_state !== "" ? " show" : ""}`}
                                id="docs_search_results"
                                data-selected_result_index={selectedResultIndex}
                            >
                                {search_sections.length > 0 ? (
                                    search_sections.map((the_subsection, index) => {
                                        let section = slugify(the_subsection.section_title ?? "");
                                        let subsection = slugify(the_subsection.post_title);
                                        let selected = selectedResultIndex === index;
                                        return (
                                            <a
                                                className={`docs_search_result${selected ? " selected" : ""}`}
                                                key={`${section}/${subsection}`}
                                                href={`/documentation/${section}/${subsection}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: `${the_subsection.sub_section_number} ${the_subsection.post_title}`,
                                                }}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    pushSectionToHistory({
                                                        section,
                                                        subsection,
                                                    }, navigate);
                                                }}
                                                data-result_index={index}
                                                data-section={section}
                                                data-subsection={subsection}
                                            ></a>
                                        );
                                    })
                                ) : (
                                    <p className="docs_no_search_result">
                                        No results were found for this search query, please try searching something else.
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                    <div className="home_docs_quick_links">
                        {home_content.docs_parents.map((section) => {
                            return (
                                <Link key={section.slug} to={`/documentation/${section.slug}`}
                                      className="docs_quick_link">
                                    <figure className="docs_quick_link_image"
                                            dangerouslySetInnerHTML={{__html: section.icon}}></figure>
                                    <h3 className="docs_quick_link_name">{section.title}</h3>
                                </Link>
                            );
                        })}
                    </div>
                    <div className="home_docs_buttons">
                        <Link to="/documentation" className="button has_icon">
                            View all documentation
                            <ArrowRight/>
                        </Link>
                    </div>
                </section>
                {is_logged_in_content(home_content) ? (
                    // @ts-ignore
                    <HomeMediaTextBlock content={home_content.checklists_block} className="checklists_block"/>
                ) : (
                    <MediaTextBlock
                        style={{gridTemplateColumns: "1fr 1fr"}}
                        image={home_content.register_cta_block.image}
                        className="register_cta_block"
                        align="full"
                    >
                        <h2>{home_content.register_cta_block.title}</h2>
                        <p>{home_content.register_cta_block.text}</p>
                        <ul className="register_cta_list">
                            {home_content.register_cta_block.list.map((list_item) => {
                                return (
                                    <li key={`${list_item.list_item}`}>
                                        <Check/>
                                        {list_item.list_item}
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="register_cta_button_group">
                            <Link to="/register" className="button has_icon">
                                Register now
                                <ArrowRight/>
                            </Link>
                            <p className="secondary_login_text">
                                Already registered? <Link to="login">Log in now</Link>
                            </p>
                        </div>
                    </MediaTextBlock>
                )}
                {is_logged_in_content(home_content) ? (
                    <section className="quick_favourites">
                        <h2 className="home_heading">Favourites</h2>
                        <div
                            className={`my_favourites${home_content.favourited_posts.length <= 0 ? " no_favourites" : ""}`}>
                            {home_content.favourited_posts.length > 0 ? (
                                home_content.favourited_posts.map((section) => {
                                    return (
                                        <React.Fragment key={section.title}>
                                            {section.posts.map((post) => {
                                                return <FavouritedSection key={post.id} post_data={post}
                                                                          section_title={section.title}
                                                                          onRemove={handleRemove}/>;
                                            })}
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <>
                                    <Favourite/>
                                    <p className="no_favourites_message">
                                        Your Favourites section is currently empty, click below to browse documentation
                                        and start adding
                                    </p>
                                    <Link to="/documentation">
                                        <button className="button">Add some favourites</button>
                                    </Link>
                                </>
                            )}
                        </div>
                        {home_content.favourited_posts.length > 0 ? (
                            <Link to="/my-account/favourites" className="button has_icon view_favourites">
                                View all favourites
                                <ArrowRight/>
                            </Link>
                        ) : null}
                    </section>
                ) : (
                    <section className="newsletter">
                        <h2 className="home_heading">Join our mailing list</h2>
                        <p>
                            Receive the latest notifications when new documentation is released, and articles with best
                            practice
                            guidance are updated
                        </p>
                        <form className="newsletter_signup_form" method="post" onSubmit={handleOnSubmit}>
                            <Input
                                type="email"
                                className="single_line_input newsletter_signup_email"
                                placeholder="Enter your email address"
                                name="email_address"
                                label={false}
                                isInvalid={() => false}
                                autoComplete="email"
                            />
                            <Submit className="button has_icon newsletter_signup_submit">
                                {loading ? "Sending..." : "Submit"}
                                <ArrowRight/>
                            </Submit>
                        </form>
                        {responseAPI ? <div className={responseAPI.cssClass}>{responseAPI.message}</div> : null}
                    </section>
                )}
                <HomeMediaTextBlock
                    isMediaOnRight={true}
                    content={home_content.network_that_cares_block}
                    className="network_that_cares_block"
                />
            </main>
        </DocumentMeta>
    );
}
