import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import DocumentMeta from "react-document-meta";
import {HiddenInput, PasswordInput, Submit} from "../components/FormInputs";
import {Lock} from "../svg";


// @ts-ignore
import { is_user_authenticated } from "../models/User";
import { metaDataT } from "../types/GlobeType";
import {NewPasswordValidationErrors, Validate, validateRegisterForm} from "../models/Validate";

export let meta = (data: any) => {
	let meta_object: metaDataT = {};
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};


const NewPassword = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false)
	const [auth_errors, setAuth_errors] = useState<{ error?: { message?: string } }>({})
	const [action_data] = useState<{ errors?: {[key: string]: string}, values?: {[key: string]: any}}>({})
	const handleOnSubmit = async (event:any) => {
		event.preventDefault()
		setLoading(true)
		const formData = new FormData(event.target);
		const errors = NewPasswordValidationErrors(formData);
		if (errors.exist) {
			setLoading(false)
			return setAuth_errors({ error: errors });

		}

		const res = await fetch(
			`${process.env.REACT_APP_WORDPRESS_API_URL}?rest_route=/simple-jwt-login/v1/user/reset_password&email=${encodeURIComponent(formData.get('email') as string)}&code=${encodeURIComponent(formData.get('code') as string)}&new_password=${encodeURIComponent(formData.get('password') as string)}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			}
		)
			.then((response) => {
				setLoading(false)
				return response.json()
			})
			.catch((error) => {
				setLoading(false)
				return error.json()
			});
		if(res.success) {
			navigate("/forgotten-password-success");
		}
		else {
			setAuth_errors({ error: { message: res.data.message }});
		}
	}

	const urlParams = new URLSearchParams(window.location.search);
	const code = urlParams.get('code')??'';
	const email = urlParams.get('email')??'';
	return (
		<DocumentMeta >
			<main className="basic_page">
				<header className="common_page_header">
					<h1 className="page_title">Forgotten password</h1>
				</header>
				<div className="basic_page_content">
					<form method="post" className="register_form" onSubmit={handleOnSubmit}>
						<PasswordInput
							name="password"
							label="Password"
							required={true}
							new_password={true}
							className="single_line_input password_field"
							id="password_field"
							leadingIcon={<Lock />}
							error={action_data?.errors?.password}
							isInvalid={Validate.is_register_password_field_invalid}
							defaultValue={action_data?.values?.password}
							autoComplete="new-password"
						/>
						<PasswordInput
							name="confirm_password"
							label="Confirm password"
							required={true}
							new_password={true}
							className="single_line_input password_field"
							id="confirm_password_field"
							leadingIcon={<Lock />}
							error={action_data?.errors?.password}
							isInvalid={Validate.is_register_password_field_invalid}
							defaultValue={action_data?.values?.password}
							autoComplete="new-password"
						/>
						<HiddenInput
							name="code"
							value={code}
						/>
						<HiddenInput
							name="email"
							value={email}
						/>
						{auth_errors.error ? <p className="login_error_message">{auth_errors.error?.message}</p> : null}
						<p>Both passwords must match.</p>
						<Submit className="button register_submit">{loading ? "Sending  ..." : "Reset"}</Submit>
					</form>

				</div>
			</main>
		</DocumentMeta>
	);
}

export default NewPassword
