export const organisation_role_options = [
	{ value: "", label: "Choose an option" },
	{ value: "Manager",label: "Manager" },
	{ value: "Technician",label: "Technician" },
	{ value: "Driver",label: "Driver" },
	{ value: "Retail",label: "Retail" },
	{ value: "Apprentice",label: "Apprentice" },
	{ value: "Other",label: "Other" },
];
export const contact_reason_for_contact_options = [
	{ value: "", label: "Choose an option" },
	{ value: "Help and support", label: "Help and support" },
	{ value: "General enquiry", label: "General enquiry" },
	{ value: "Technical question", label: "Technical question" },
];
