import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import { CommonPageHeader } from "../components/CommonPageHeader";
import {
	AcceptanceCheckbox,
	Honeypot,
	Input,
	SelectInput,
	Submit,
	TextArea,
} from "../components/FormInputs";
import { contact_reason_for_contact_options } from "../models/Data";
import { Validate, validateContactForm } from "../models/Validate";

import {metaDataT, SeoDataT} from "../types/GlobeType";
import DocumentMeta from "react-document-meta";
import {useLoaderData} from "../hooks/useLoaderData";


export let meta = ({ data }: { data: SeoDataT }) => {
	let meta_object: metaDataT = {
		title: 'Contact us - Fit For Reuse'
	};
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};

// @ts-ignore
export default function Contact() {
	const [responseAPI, setResponseAPI] = useState<{ message: string  }>()
	const [action_data, setActionData] = useState({
		errors: {
			full_name: undefined,
			email_address: undefined,
			reason_for_contact: undefined,
			subject: undefined,
			message: undefined,
			terms_group: undefined,
			data_privacy_group: undefined,
			exist: false
		},
		values: {
			full_name: '',
			email_address: '',
			reason_for_contact: '',
			subject: '',
			message: '',
			data_privacy: undefined,
			terms: undefined,
			terms_group: '',
		}
	})
	// eslint-disable-next-line no-restricted-globals
	const [seoData] = useLoaderData(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_post_by_url${location.pathname}`, {})
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		window.scrollTo({ left: 0, top: 0})
	}, []);

	const handleOnSubmit = async (event:any) => {
		event.preventDefault()
		setLoading(true)
		const formData = new FormData(event.target);
		const errors = validateContactForm(formData);
		if (errors.exist) {
			setLoading(false);

			return { errors, values: Object.fromEntries(formData) };
		}

		let form_data = new URLSearchParams();
		if(formData) {
			const email = formData.get('email_address')?.toString() ?? ""
			if(email) {
				form_data.set("email_address", email);
			}

			const reason = formData.get('reason_for_contact')?.toString() ?? ""
			if(reason) {
				form_data.set("reason_for_contact", reason);
			}

			const name = formData.get('full_name')?.toString() ?? ""
			if(name) {
				form_data.set("full_name", name);
			}

			const subject = formData.get('subject')?.toString() ?? ""
			if(subject) {
				form_data.set("subject", subject);
			}

			const message = formData.get('message')?.toString() ?? ""
			if(message) {
				form_data.set("message", message);
			}

			const data_privacy = formData.get('data_privacy')?.toString() ?? ""
			if(data_privacy) {
				form_data.set("data_privacy", data_privacy);
			}

			const terms = formData.get('terms')?.toString() ?? ""
			if(terms) {
				form_data.set("terms", terms);
			}

		}

		const res = await fetch(
			`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/contact`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: form_data,
			}
		)
			.then((response) => {
				setLoading(false)
				return response
			})
			.catch((error) => {
				setLoading(false)
				return error.json()
			});
		if(res.status === 200) {
			setResponseAPI({message: 'E-mail has been sent.'});
			event.target.reset();
		}
		else {
			setResponseAPI({message: res.data  });
		}
		setActionData( res )
	}

	return (
		<DocumentMeta {...meta(seoData)}>
			<main className="contact_page">
				<CommonPageHeader title="Contact us" />
				<div className="contact_page_content">
					<aside className="contact_info_sidebar">
						<address>
							<p className="company_name">Fit for Reuse</p>
							<p>48-54 West St, St Philips, Bristol, BS2 0BL</p>
							<p>
								<span className="prefix">Email:</span>{" "}
								<a href="mailto:info@fit-for-reuse.org.uk">info@fit-for-reuse.org.uk</a>
							</p>
							<p>
								<span className="prefix">Phone:</span> <a href="tel:+448000858339">0800 085 8339</a>
							</p>
						</address>
					</aside>
					<form className="contact_form" method="post" noValidate onSubmit={handleOnSubmit}>
						<Input
							name="full_name"
							label="Name"
							type="text"
							required={true}
							className="single_line_input name_field"
							id="name_field"
							error={action_data?.errors?.full_name}
							isInvalid={Validate.is_name_field_invalid}
							defaultValue={action_data?.values?.full_name}
							autoComplete="name"
						/>
						<Input
							name="email_address"
							label="Email address"
							type="email"
							required={true}
							className="single_line_input email_field"
							id="email_field"
							error={action_data?.errors?.email_address}
							isInvalid={Validate.is_email_field_invalid}
							defaultValue={action_data?.values?.email_address}
							autoComplete="email"
						/>
						<SelectInput
							name="reason_for_contact"
							label="Reason for contact"
							required={true}
							className="select_input reason_for_contact_field"
							id="reason_for_contact_field"
							options={contact_reason_for_contact_options}
							error={action_data?.errors?.reason_for_contact}
							isInvalid={Validate.is_reason_for_contact_field_invalid}
							defaultValue={action_data?.values?.reason_for_contact}
						/>
						<Input
							name="subject"
							label="Subject"
							type="text"
							required={true}
							className="single_line_input subject_field"
							id="subject_field"
							error={action_data?.errors?.subject}
							isInvalid={Validate.is_subject_field_invalid}
							defaultValue={action_data?.values?.subject}
						/>
						<TextArea
							name="message"
							label="Message"
							required={true}
							className="multi_line_input message_field"
							id="message_field"
							error={action_data?.errors?.message}
							isInvalid={Validate.is_message_field_invalid}
							defaultValue={action_data?.values?.message}
						/>
						<AcceptanceCheckbox
							label="Data privacy"
							className="data_privacy_checkbox"
							error={action_data?.errors?.data_privacy_group}
							name="data_privacy"
							required={true}
							id="data_privacy_checkbox"
							isInvalid={Validate.is_data_privacy_checkbox_invalid}
							checkboxLabel={`The personal data you provide is used to answer queries, process orders or allow access to specific information. You have the right to modify and delete all the personal information found in the "My Account" page.`}
							defaultChecked={action_data?.values?.data_privacy}
						/>
						<AcceptanceCheckbox
							label="Terms and conditions"
							className="terms_checkbox"
							error={action_data?.errors?.terms_group}
							name="terms"
							required={true}
							id="terms_checkbox"
							isInvalid={Validate.is_terms_checkbox_invalid}
							checkboxLabel={
								<>
									I agree to the <Link to="/terms-and-conditions">Terms and Conditions</Link> and the{" "}
									<Link to="/privacy-policy">Privacy Policy</Link>.
								</>
							}
							defaultChecked={action_data?.values?.terms}
						/>
						<Honeypot />
						<hr />
						{action_data?.errors?.exist ? (
							<p className="validation_errors">
								There are one or more validation errors above, you will need to fix these before you can send your
								message.
							</p>
						) : null}
						<Submit className="button contact_submit">{loading ? "Sending  ..." : "Send"}</Submit>
						{responseAPI ? <p>{responseAPI.message}</p> : null}
					</form>
				</div>
			</main>
		</DocumentMeta>
	);
}
