import React, {useState} from 'react'
import { Lock, Cancel, CheckCircle } from "../../svg";
import {Link, useNavigate} from "react-router-dom";
import {
	Input,
	PasswordInput,
	SelectInput,
	AcceptanceCheckbox,
	Submit,
	Reset,
} from "../../components/FormInputs";
import {
	update_current_user,
} from "../../models/User";
import { organisation_role_options } from "../../models/Data";
import { validateMyAccountForm, Validate } from "../../models/Validate";
import { User } from "../../types/GlobeType";
import DocumentMeta from "react-document-meta";

// @ts-ignore
export let meta = () => {
	let meta_object: any = {};
	meta_object.title = "Account details - Fit For Reuse";
	return meta_object;
};

// @ts-ignore
export let action = (user, event, token) => {
	const formData = new FormData(event.target);
	const errors = validateMyAccountForm(formData);
	if (errors.exist === true) {
		return { errors, values: Object.fromEntries(formData) };
	}

	let data = {
		email: formData.get("email_address")?.toString() ?? "",
		password: formData.get("password")?.toString() ?? "",
		first_name: formData.get("first_name")?.toString() ?? "",
		last_name: formData.get("last_name")?.toString() ?? "",
		meta: {
			organisation: formData.get("organisation")?.toString() ?? "",
			organisation_role: formData.get("organisation_role")?.toString() ?? "",
		},
	};

	update_current_user(user, data, token).then(() => {
		// eslint-disable-next-line no-restricted-globals
		location.reload()
	});
	return null;

};



// @ts-ignore
export default function Details({ user, token }: { user: false | User | undefined; token: false | string }) {
	const navigate = useNavigate();
	const [action_data, setActionData] = useState({
		errors: {
			email_address: undefined,
			password: undefined,
			first_name: undefined,
			last_name: undefined,
			organisation: undefined,
			organisation_role: undefined,
			newsletter_signup_group: undefined,
			exist: false
		}
	});

	if (!user) {
		navigate("/login");
		return <div />
	}

	const defaultValues = {
		email_address: user.email,
		password: "",
		first_name: user.first_name,
		last_name: user.last_name,
		organisation: user.meta.organisation ?? "",
		organisation_role: user.meta.organisation_role ?? "",
		newsletter_signup: false
  	};

	const handleOnSubmit = (event :any) => {
		event.preventDefault()
		// @ts-ignore
		setActionData(action(user, event, token))
	}

	return (
		<DocumentMeta {...meta()}>
			<main className="my_account_content">
				<form method="post" className="my_account_form" noValidate onSubmit={handleOnSubmit}>
					<h2>Edit your profile</h2>
					<Input
						name="email_address"
						label="Email address"
						type="email"
						required={true}
						className="single_line_input email_field"
						id="email_field"
						error={action_data?.errors?.email_address}
						isInvalid={Validate.is_email_field_invalid}
						defaultValue={defaultValues.email_address}
						autoComplete="off"
					/>
					<PasswordInput
						name="password"
						label="Password (leave blank to not change)"
						new_password={true}
						className="single_line_input password_field"
						id="password_field"
						leadingIcon={<Lock />}
						error={action_data?.errors?.password}
						isInvalid={Validate.is_my_account_password_field_invalid}
						defaultValue={defaultValues.password}
						labelSuffix={false}
						autoComplete="off"
					/>
					<hr />
					<h2>Your personal details</h2>
					<Input
						name="first_name"
						label="First name"
						type="text"
						required={true}
						className="single_line_input first_name_field"
						id="first_name_field"
						error={action_data?.errors?.first_name}
						isInvalid={Validate.is_first_name_field_invalid}
						defaultValue={defaultValues.first_name}
						autoComplete="off"
					/>
					<Input
						name="last_name"
						label="Last name"
						type="text"
						required={true}
						className="single_line_input last_name_field"
						id="last_name_field"
						error={action_data?.errors?.last_name}
						isInvalid={Validate.is_last_name_field_invalid}
						defaultValue={defaultValues.last_name}
						autoComplete="off"
					/>
					<Input
						name="organisation"
						label="Organisation name"
						type="text"
						required={true}
						className="single_line_input organisation_field"
						id="organisation_field"
						error={action_data?.errors?.organisation}
						isInvalid={Validate.is_organisation_field_invalid}
						defaultValue={defaultValues.organisation}
						autoComplete="off"
					/>
					<SelectInput
						name="organisation_role"
						label="Organisation role"
						required={true}
						className="select_input organisation_role_field"
						id="organisation_role_field"
						options={organisation_role_options}
						error={action_data?.errors?.organisation_role}
						isInvalid={Validate.is_organisation_role_field_invalid}
						defaultValue={defaultValues.organisation_role}
					/>
					<hr />
					<h2>Newsletter opt-in/out</h2>
					<AcceptanceCheckbox
						label="Sign up for our newsletter"
						className="newsletter_signup_checkbox"
						error={action_data?.errors?.newsletter_signup_group}
						name="newsletter_signup"
						required={false}
						id="newsletter_signup_checkbox"
						isInvalid={() => false}
						checkboxLabel={`Sign up for our newsletter`}
						defaultChecked={defaultValues.newsletter_signup}
					/>
					{
						action_data?.errors?.exist ? (
						<p className="validation_errors">
							There are one or more validation errors above, you will need to fix these before you update your account.
						</p>
					) : null}
					<div className="button_group">
						<Link to="/my-account/delete" className="button outline my_account_delete">
							Delete my account
						</Link>
						<Reset className="button outline my_account_cancel">
							Reset <Cancel />
						</Reset>
						<Submit className="button my_account_submit">
							Save changes <CheckCircle />
						</Submit>
					</div>
				</form>
			</main>
		</DocumentMeta>
	);
}
