import React from 'react'
import { ArrowRight } from "../svg";
import { MediaTextBlock } from "./MediaTextBlock";

export function HomeMediaTextBlock({
	className,
	content,
	isMediaOnRight,
	hasFullWidthImg,
}: {
	className?: string;
	content: MediaTextBlockContent;
	isMediaOnRight?: boolean;
	hasFullWidthImg?: boolean;
}) {
	return (
		<MediaTextBlock
			style={{ gridTemplateColumns: "1fr 1fr" }}
			image={content.image}
			isMediaOnRight={isMediaOnRight}
			hasFullWidthImg={hasFullWidthImg}
			className={className}
			align="full"
		>
			<h2>{content.title}</h2>
			<p>{content.text}</p>
			<div className="button_group">
				{content && content.buttons && content.buttons.map((button) => {
					return (
						<a
							key={button.link.title}
							href={button.link.url}
							className={`button${button.button_type !== "normal" ? ` ${button.button_type}` : ""}${
								button.has_arrow ? " has_icon" : ""
							}`}
							target={button.link.target !== "" ? button.link.target : "_self"}
						>
							{button.link.title}
							{button.has_arrow ? <ArrowRight /> : null}
						</a>
					);
				})}
			</div>
		</MediaTextBlock>
	);
}
