import React from 'react'
import { ArrowRight } from "../../svg";
import { SaveChecklist } from "./save-checklist";
import {metaDataT} from "../../types/GlobeType";
import { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import DocumentMeta from "react-document-meta";
import Loader from "../../components/Loader";
import {redirectUnauthenticatedToLoginAndReturnAfter} from "../../models/User";


// @ts-ignore
export let meta = (data: any) => {
	let meta_object: metaDataT = {};
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};

// @ts-ignore
export default function Checklists({ user, token }) {
	const navigate = useNavigate();
	const { slug } = useParams()
	let [isLoading, setIsLoading] = useState(true);
	const [checklist_data, setChecklistData] = useState({
		id: 0,
		pdf: '',
		title: '',
		pdf_size: '/',
		last_updated: {
			prefix: '',
			date: '',
			time: ''
		}
	})

	useEffect(() => {
		if (token) {
			fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_single_checklist/${slug}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(async (response) => {
				if (response.status !== 200) {
					throw JSON.stringify( { status: response.status,response});
				}
				return response;
			})
			.then((response) => response.json()).then((results) => {
				setIsLoading(false)
				setChecklistData(results)
			});
		} else {
			// eslint-disable-next-line no-restricted-globals
			redirectUnauthenticatedToLoginAndReturnAfter(location.pathname, user, navigate);
		}
	}, [navigate, user, slug, token])

	// @ts-ignore
	let pdf_width = checklist_data?.pdf_size?.split("/")[0];
	let pdf_height = checklist_data?.pdf_size?.split("/")[1];
	let pdf_orientation = pdf_width > pdf_height ? "landscape" : "portrait";

	if (isLoading) {
		return <Loader />
	}

	return (
		<DocumentMeta {...meta(checklist_data)}>
			<main className="checklist_single_page">
				<div className="site_container">
					<header className="checklist_single_header">
						<div className="checklist_info">
							<h1 className="checklist_title">{checklist_data.title}</h1>
							<p className="last_updated">
								{checklist_data?.last_updated?.prefix} {checklist_data?.last_updated?.date} at{" "}
								{checklist_data?.last_updated?.time}
							</p>
						</div>
						<div className="checklist_actions">
							<a href={checklist_data.pdf} className="button has_icon" download={checklist_data.title} target="_blank" rel="noreferrer">
								Download
								<ArrowRight />
							</a>
							<SaveChecklist id={checklist_data.id} user={user} token={token} />
						</div>
					</header>
				</div>
				<div className={`iframe_container ${pdf_orientation}`} style={{ aspectRatio: checklist_data.pdf_size }}>
					<p className="PDF_fallback_message" aria-hidden>
						Your browser doesn't support PDF previews. If it has not already downloaded, please click the download button
						above to view this PDF in your own PDF viewer.
					</p>
					<iframe key={checklist_data.id} src={`${checklist_data.pdf}#toolbar=0`} frameBorder="0"></iframe>
				</div>
			</main>
		</DocumentMeta>
	);
}
