import { useEffect, useRef, useState } from "react";

export const useStateWithCallback = (initialValue: any) => {
	const callbackRef = useRef<any>(null);

	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		if (callbackRef.current) {
			callbackRef.current(value);

			callbackRef.current = null;
		}
	}, [value]);

	const setValueWithCallback = (newValue: any, callback?: CallableFunction) => {
		if (callback) {
			callbackRef.current = callback;
		}

		return setValue(newValue);
	};

	return [value, setValueWithCallback];
};
