import React from 'react'
import { MediaTextBlock } from "../components/MediaTextBlock";
import { Logo} from "../svg";
import type { SeoDataT } from '../types/GlobeType'
import {useLoaderData} from "../hooks/useLoaderData";
import {metaDataT} from "../types/GlobeType";
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from "react";
import DocumentMeta from "react-document-meta";
import Loader from "../components/Loader";


interface AboutContent {
	icon_sections: {
		icon: string;
		title: string;
		text: string;
	}[];
	quote_section: {
		text: string;
		author: {
			name: string;
			job_title: string;
		};
	};
}

export let meta = (data: any) => {
	let meta_object: metaDataT = {};
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};

export default function About() {
	let [about_content, isLoading] = useLoaderData(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_about_content/`, {
			icon_sections: [],
			quote_section: {
				text: '',
				author: {
					name: '',
					job_title: ''
				}
			},
	}, {});

	if (isLoading) {
		return <Loader />
	}

	return (
		<DocumentMeta {...meta(about_content)}>
			<main className="about_page">
				{about_content.icon_sections.map((section: { title: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; icon: any; text: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; }, index: number) => {
					return (
						<div key={`${section.title}`} className="icon_section">
							<MediaTextBlock image={section.icon} isMediaOnRight={!(index % 2)}>
								<h2>{section.title}</h2>
								<p>{section.text}</p>
							</MediaTextBlock>
						</div>
					);
				})}
				<section className="quote_section">
					<div className="quote_logo">
						<Logo />
					</div>
					<p className="the_quote">{about_content.quote_section.text}</p>
					<div className="author">
						<p className="author_name">{about_content.quote_section.author.name}</p>
						<p className="author_job_title">{about_content.quote_section.author.job_title}</p>
					</div>
				</section>
			</main>
		</DocumentMeta>
	);
}
