import React, { TextareaHTMLAttributes } from "react";
import { InputHTMLAttributes, SelectHTMLAttributes, useState, useEffect, HTMLAttributes } from "react";
import zxcvbn from "zxcvbn";
import type { ZXCVBNResult } from "zxcvbn";
import { PasswordShown, PasswordHidden, DropdownThick, Checkbox, Lock } from "../svg";
import { useStateWithCallback } from "../hooks/useStateWithCallback";

function filterProps(props: any, propsToFilter: string[]) {
	let newProps: any = {};
	let oldPropskeys = Object.keys(props);
	let oldPropsValues = Object.values(props);
	for (let $i = 0; $i < oldPropskeys.length; $i++) {
		if (!propsToFilter.includes(oldPropskeys[$i])) {
			newProps[oldPropskeys[$i]] = oldPropsValues[$i];
		}
	}
	return newProps;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
	label?: string | JSX.Element | false;
	leadingIcon?: JSX.Element;
	trailingIcon?: JSX.Element;
	error?: string;
	isInvalid: CallableFunction;
	labelSuffix?: boolean;
}

export let Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
	let { name, className, id, required, label, leadingIcon, trailingIcon, error, isInvalid, disabled, labelSuffix } =
		props;
	labelSuffix = labelSuffix === false ? false : true;
	trailingIcon = disabled ? <Lock /> : trailingIcon;
	const [validationError, setValidationError] = useState(error);
	label = label ?? name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
	let className_obj = className?.split(" ") ?? [];
	let wrapperClassName = ["field_wrapper"];
	className_obj.forEach((className) => {
		wrapperClassName.push(`${className}_wrapper`);
	});
	if (required) {
		wrapperClassName.push(`required_field`);
	}
	if (leadingIcon) {
		wrapperClassName.push(`has_leading_icon`);
	}
	if (trailingIcon) {
		wrapperClassName.push(`has_trailing_icon`);
	}
	if (disabled) {
		wrapperClassName.push(`disabled_field`);
	}
	if (error) {
		wrapperClassName.push(`has_error`);
	}
	if (!labelSuffix || disabled) {
		wrapperClassName.push(`no_label_suffix`);
	}
	let input_props: InputHTMLAttributes<HTMLInputElement> = filterProps(props, [
		"label",
		"leadingIcon",
		"trailingIcon",
		"error",
		"isInvalid",
		"labelSuffix",
	]);

	useEffect(() => {
		setValidationError(error);
	}, [error]);

	return (
		<>
			<div className={wrapperClassName.join(" ")}>
				{!validationError && !label ? null : (
					<div className="label_wrapper">
						{label ? (
							<label htmlFor={id} className="field_label">
								{label}
							</label>
						) : null}
						{validationError ? <p className="validation_error">{validationError}</p> : null}
					</div>
				)}
				<div className="input_wrapper">
					{leadingIcon ? <div className="leading_icon">{leadingIcon}</div> : null}
					<input ref={ref} autoComplete="on" {...input_props} onBlur={(event) => setValidationError(isInvalid(event.target.value))} />
					{trailingIcon ? <div className="trailing_icon">{trailingIcon}</div> : null}
				</div>
			</div>
		</>
	);
});

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	name: string;
	label?: string | JSX.Element | false;
	error?: string;
	isInvalid: CallableFunction;
	labelSuffix?: boolean;
}

export function TextArea(props: TextAreaProps) {
	let { name, className, id, required, label, error, isInvalid, disabled, labelSuffix } = props;
	labelSuffix = labelSuffix === false ? false : true;
	const [validationError, setValidationError] = useState(error);
	label = label ?? name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
	let className_obj = className?.split(" ") ?? [];
	let wrapperClassName = ["field_wrapper"];
	className_obj.forEach((className) => {
		wrapperClassName.push(`${className}_wrapper`);
	});
	if (required) {
		wrapperClassName.push(`required_field`);
	}
	if (disabled) {
		wrapperClassName.push(`disabled_field`);
	}
	if (error) {
		wrapperClassName.push(`has_error`);
	}
	if (!labelSuffix || disabled) {
		wrapperClassName.push(`no_label_suffix`);
	}
	let textarea_props: TextareaHTMLAttributes<HTMLTextAreaElement> = filterProps(props, [
		"label",
		"error",
		"isInvalid",
		"labelSuffix",
	]);

	useEffect(() => {
		setValidationError(error);
	}, [error]);

	return (
		<>
			<div className={wrapperClassName.join(" ")}>
				{!validationError && !label ? null : (
					<div className="label_wrapper">
						{label ? (
							<label htmlFor={id} className="field_label">
								{label}
							</label>
						) : null}
						{validationError ? <p className="validation_error">{validationError}</p> : null}
					</div>
				)}
				<div className="input_wrapper">
					<textarea
						{...textarea_props}
						onBlur={(event) => setValidationError(isInvalid(event.target.value))}
					></textarea>
				</div>
			</div>
		</>
	);
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
	name: string;
	label?: string | JSX.Element | false;
	leadingIcon?: JSX.Element;
	options: SelectOption[];
	error?: string;
	isInvalid: CallableFunction;
	labelSuffix?: boolean;
}

export function SelectInput(props: SelectProps) {
	let { name, className, id, required, label, leadingIcon, options, defaultValue, error, isInvalid, labelSuffix } =
		props;
	labelSuffix = labelSuffix === false ? false : true;
	const [validationError, setValidationError] = useState(error);
	defaultValue = defaultValue ?? "";
	const [selectedOption, setSelectedOption] = useState(
		options.find((option) => option.value === defaultValue) ?? { value: "", label: "Choose an option." }
	);
	const [showOptions, setShowOptions] = useStateWithCallback(false);
	label = label ?? name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
	let className_obj = className?.split(" ") ?? [];
	let wrapperClassName = ["field_wrapper", "has_trailing_icon"];
	className_obj.forEach((className) => {
		wrapperClassName.push(`${className}_wrapper`);
	});
	if (required) {
		wrapperClassName.push(`required_field`);
	}
	if (leadingIcon) {
		wrapperClassName.push(`has_leading_icon`);
	}
	if (error) {
		wrapperClassName.push(`has_error`);
	}
	if (!labelSuffix) {
		wrapperClassName.push(`no_label_suffix`);
	}
	function get_prev_option(currentOption: SelectOption) {
		const currentIndex = options.indexOf(currentOption);
		let prevIndex = currentIndex - 1;
		if (prevIndex < 0) {
			return options[currentIndex];
		}
		return options[prevIndex];
	}
	function get_next_option(currentOption: SelectOption) {
		const currentIndex = options.indexOf(currentOption);
		let nextIndex = currentIndex + 1;
		if (nextIndex >= options.length) {
			return options[currentIndex];
		}
		return options[nextIndex];
	}
	function handleSelectKeyboardInputs(event: any) {
		let handled_keycodes = ["Tab", "Escape", "Enter", "Space", "ArrowUp", "ArrowLeft", "ArrowDown", "ArrowRight"];
		if (!handled_keycodes.includes(event.code)) {
			return;
		}
		if (event.code === "Tab") {
			setShowOptions(false);
			// MUST return before prevent default to make sure focus shift is not effected.
			return;
		}
		event.preventDefault();

		let current_option = options.find((option) => {
			return option === selectedOption;
		});
		if (!current_option) {
			return;
		}

		if (event.code === "Enter") {
			setShowOptions(!showOptions);
			return;
		}
		if (event.code === "Space") {
			setShowOptions(true);
			return;
		}
		if (event.code === "Escape") {
			setShowOptions(false);
			return;
		}
		if (event.code === "ArrowUp" || event.code === "ArrowLeft") {
			setSelectedOption(get_prev_option(current_option));
			return;
		}
		if (event.code === "ArrowRight" || event.code === "ArrowDown") {
			setSelectedOption(get_next_option(current_option));
			return;
		}
	}
	function toggleShowOptions() {
		setShowOptions(!showOptions);
	}
	function handleSelectClick() {
		toggleShowOptions();
	}
	/**
	 * Simulate a click event.
	 * @public
	 * @param {Element} element the element to simulate a click on
	 */
	var simulateClick = function (element: Element) {
		var event = new MouseEvent("click", {
			bubbles: true,
			cancelable: true,
			view: window,
		});
		var canceled = !element.dispatchEvent(event);
	};
	function is_really_a_text_input(element: Element): element is HTMLInputElement {
		if (
			element instanceof HTMLInputElement &&
			[
				"text",
				"search",
				"tel",
				"url",
				"email",
				"password",
				"date",
				"month",
				"week",
				"time",
				"datetime-local",
				"number",
			].includes(element.type)
		) {
			return true;
		}
		return false;
	}

	useEffect(() => {
		setValidationError(error);
	}, [error]);

	return (
		<>
			<div className={wrapperClassName.join(" ")}>
				<div className="label_wrapper">
					{label !== false ? <p className="field_label">{label}</p> : null}
					{validationError ? <p className="validation_error">{validationError}</p> : null}
				</div>
				<div className="input_wrapper">
					{leadingIcon ? <div className="leading_icon">{leadingIcon}</div> : null}
					{/*
					I was very close to making a replacement select which worked with keyboard inputs and mouse clicks.
					There was a weird bug where randomly sometimes the text wouldn't change for the user, even though the
					code changed as expected. I don't have time to debug this, so I'm going to replace with a normal select
					for all users. This only means that the select dropdown cannot be styled.
					I've left the code here in case anyone wanted to try and fix it.

					<div
						className={`select_input_container${className ? ` ${className}` : ""} hide-if-no-js`}
						onClick={handleSelectClick}
						onKeyDown={handleSelectKeyboardInputs}
						id={id}
						tabIndex={0}
						onBlur={() => setValidationError(isInvalid(selectedOption.value ?? selectedOption.label, options))}
					>
						<input
							type="text"
							name={name}
							defaultValue={selectedOption.value ?? selectedOption.label}
							className="single_line_input select_real_input"
							id={`select_real_input_${name}`}
							required={required}
							tabIndex={-1}
							autoComplete="false"
						/>
						<input
							type="text"
							name={name}
							defaultValue={selectedOption.label}
							className="single_line_input select_false_input"
							required={false}
							tabIndex={-1}
							autoComplete="false"
						/>
						<div className={`select_options_group${showOptions ? " show" : ""}`}>
							{options.map((option) => {
								let selectedOptionValue = selectedOption.value ?? selectedOption.label;
								let value = option.value ?? option.label;
								return (
									<div
										key={value}
										id={`single_option_${value === "" ? "default" : `${value.toLowerCase().replaceAll(" ", "")}`}`}
										className={`select_option${selectedOptionValue === value ? " selected" : ""}`}
										onClick={() => {
											setSelectedOption(option);
											setShowOptions(false);
										}}
									>
										{option.label}
									</div>
								);
							})}
						</div>
						<div
							onClick={function (event) {
								setShowOptions(false, function () {
									let element_under_overlay = document.elementFromPoint(event.clientX, event.clientY);
									if (element_under_overlay) {
										// Ignore if reclicking select
										if (element_under_overlay.classList.contains("select_input")) {
											return;
										}
										// If clicking outside of select options to close select, don't prevent clicks on other elements.
										simulateClick(element_under_overlay);
										// Text and text area elements are not focused on simulated clicks, so we have to do that manually.
										if (
											is_really_a_text_input(element_under_overlay) ||
											element_under_overlay instanceof HTMLTextAreaElement
										) {
											element_under_overlay.focus();
										}
									}
								});
							}}
							className={`select_options_backdrop${showOptions ? " show" : ""}`}
						></div>
					</div>
					If you fix the above code, add "hide-if-js" class to the below select element to hide it for JS users.
					*/}
					<select
						className="single_line_input select_fallback_input"
						name={name}
						defaultValue={selectedOption.value ?? selectedOption.label}
					>
						{options.map((option) => {
							let value = option.value ?? option.label;
							return (
								<option key={value} value={value}>
									{option.label}
								</option>
							);
						})}
					</select>
					<div className="trailing_icon select_dropdown_arrow">
						<DropdownThick />
					</div>
				</div>
			</div>
		</>
	);
}

interface PasswordFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
	label?: string | JSX.Element | false;
	new_password: boolean;
	leadingIcon?: JSX.Element;
	error?: string;
	isInvalid: CallableFunction;
	labelSuffix?: boolean;
}
export function PasswordInput(props: PasswordFieldProps) {
	let { name, className, id, required, label, new_password, leadingIcon, error, isInvalid, disabled, labelSuffix } =
		props;
	labelSuffix = labelSuffix === false ? false : true;
	const [validationError, setValidationError] = useState(error);
	label = label ?? name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
	let className_obj = className?.split(" ") ?? [];
	let wrapperClassName = ["field_wrapper", "has_trailing_icon"];
	className_obj.forEach((className) => {
		wrapperClassName.push(`${className}_wrapper`);
	});
	if (required) {
		wrapperClassName.push(`required_field`);
	}
	if (leadingIcon) {
		wrapperClassName.push(`has_leading_icon`);
	}
	if (error) {
		wrapperClassName.push(`has_error`);
	}
	if (disabled) {
		wrapperClassName.push(`disabled_field`);
	}
	if (!labelSuffix || disabled) {
		wrapperClassName.push(`no_label_suffix`);
	}

	let input_props: InputHTMLAttributes<HTMLInputElement> = filterProps(props, [
		"label",
		"leadingIcon",
		"new_password",
		"error",
		"isInvalid",
		"labelSuffix",
	]);
	const [passwordValue, setPasswordValue] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [ZXCVBNResult, setZXCVBNResult] = useState<ZXCVBNResult>({
		calc_time: 2,
		crack_times_display: {
			offline_fast_hashing_1e10_per_second: "less than a second",
			offline_slow_hashing_1e4_per_second: "less than a second",
			online_no_throttling_10_per_second: "less than a second",
			online_throttling_100_per_hour: "36 seconds",
		},
		crack_times_seconds: {
			offline_fast_hashing_1e10_per_second: 1e-10,
			offline_slow_hashing_1e4_per_second: 0.0001,
			online_no_throttling_10_per_second: 0.1,
			online_throttling_100_per_hour: 36,
		},
		feedback: {
			warning: "",
			suggestions: ["Use a few words, avoid common phrases", "No need for symbols, digits, or uppercase letters"],
		},
		guesses: 1,
		guesses_log10: 0,
		score: 0,
		sequence: [],
	});

	useEffect(() => {
		setZXCVBNResult(zxcvbn(passwordValue));
	}, [passwordValue]);

	useEffect(() => {
		setValidationError(error);
	}, [error]);

	return (
		<>
			<div className={wrapperClassName.join(" ")}>
				<div className="label_wrapper">
					{label !== false ? (
						<label htmlFor={id} className="field_label">
							{label}
						</label>
					) : null}
					{validationError ? <p className="validation_error">{validationError}</p> : null}
				</div>
				<div className="input_wrapper">
					{leadingIcon ? <div className="leading_icon">{leadingIcon}</div> : null}
					<input
						type={showPassword ? "text" : "password"}
						onChange={(event) => setPasswordValue(event.target.value)}
						onBlur={(event) => setValidationError(isInvalid(event.target.value))}
						{...input_props}
					/>
					{disabled ? (
						<div className="trailing_icon">
							<Lock />
						</div>
					) : showPassword ? (
						<div className="trailing_icon">
							<PasswordShown onClick={() => setShowPassword(!showPassword)} className="hide-if-no-js" />
						</div>
					) : (
						<div className="trailing_icon">
							<PasswordHidden onClick={() => setShowPassword(!showPassword)} className="hide-if-no-js" />
						</div>
					)}
				</div>
				{new_password ? (
					<div className="password_strength_information hide-if-no-js">
						<meter
							min={-1}
							max={4}
							low={2}
							high={3}
							value={passwordValue === "" ? -1 : ZXCVBNResult.score}
							optimum={3.99}
						></meter>
						{passwordValue.length > 0 && ZXCVBNResult.score <= 2 ? (
							<>
								{ZXCVBNResult.feedback.warning !== "" ? (
									<>
										<strong>Warning: {ZXCVBNResult.feedback.warning}</strong>
										<br />
									</>
								) : null}
								<strong>Suggestions:</strong>
								<ul className="suggestion_list">
									{ZXCVBNResult.feedback.suggestions.map((suggestion) => {
										return <li key={suggestion}>{suggestion}</li>;
									})}
								</ul>
							</>
						) : null}
					</div>
				) : null}
			</div>
		</>
	);
}

export function Submit({ children, className, id }: { children: React.ReactNode; className?: string; id?: string }) {
	return (
		<button type="submit" id={id} className={className}>
			{children}
		</button>
	);
}

export function Reset({ children, className, id }: { children: React.ReactNode; className?: string; id?: string }) {
	return (
		<button type="reset" id={id} className={className}>
			{children}
		</button>
	);
}

interface CheckboxGroupProps extends HTMLAttributes<HTMLElement> {
	label: string | JSX.Element | false;
	required?: boolean;
	children: JSX.Element;
	error?: string;
	labelSuffix?: boolean;
}

export function CheckboxGroup(props: CheckboxGroupProps) {
	let { className, required, label, children, error, labelSuffix } = props;
	labelSuffix = labelSuffix === false ? false : true;
	let className_obj = className?.split(" ") ?? [];
	let wrapperClassName = ["checkbox_group_wrapper"];
	className_obj.forEach((className) => {
		wrapperClassName.push(`${className}_wrapper`);
	});
	if (required) {
		wrapperClassName.push(`required_field`);
	}
	if (error) {
		wrapperClassName.push(`has_error`);
	}
	if (!labelSuffix) {
		wrapperClassName.push(`no_label_suffix`);
	}
	return (
		<>
			<div className={wrapperClassName.join(" ")}>
				<div className="label_wrapper">
					{label !== false ? <p className="field_label">{label}</p> : null}
					{error ? <p className="validation_error">{error}</p> : null}
				</div>
				<div className="input_wrapper">{children}</div>
			</div>
		</>
	);
}

interface CheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
	label?: string | JSX.Element;
}

export function CheckboxInput(props: CheckboxInputProps) {
	let { name, className, id, label } = props;
	label = label ?? name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
	let className_obj = className?.split(" ") ?? [];
	let wrapperClassName = ["checkbox_wrapper"];
	className_obj.forEach((className) => {
		wrapperClassName.push(`${className}_wrapper`);
	});
	let input_props: InputHTMLAttributes<HTMLInputElement> = filterProps(props, ["label"]);
	input_props.className = `checkbox${className ? ` ${className}` : ""}`;
	return (
		<>
			<label htmlFor={id} className={wrapperClassName.join(" ")}>
				<input type="checkbox" {...input_props} />
				<Checkbox />
				<span>{label}</span>
			</label>
		</>
	);
}

interface AcceptanceCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
	label: string | JSX.Element | false;
	checkboxLabel: string | JSX.Element | false;
	required?: boolean;
	error?: string;
	labelSuffix?: boolean;
	isInvalid: CallableFunction;
}

export function AcceptanceCheckbox(props: AcceptanceCheckboxProps) {
	let { className, required, label, error, labelSuffix, id, isInvalid, checkboxLabel } = props;
	const [validationError, setValidationError] = useState(error);
	labelSuffix = labelSuffix === false ? false : true;
	let className_obj = className?.split(" ") ?? [];
	let groupWrapperClassName = ["checkbox_group_wrapper"];
	let wrapperClassName = ["checkbox_wrapper"];
	className_obj.forEach((className) => {
		groupWrapperClassName.push(`${className}_group_wrapper`);
		wrapperClassName.push(`${className}_wrapper`);
	});
	if (required) {
		groupWrapperClassName.push(`required_field`);
	}
	if (error) {
		groupWrapperClassName.push(`has_error`);
	}
	if (!labelSuffix) {
		groupWrapperClassName.push(`no_label_suffix`);
	}
	let input_props: InputHTMLAttributes<HTMLInputElement> = filterProps(props, [
		"label",
		"error",
		"isInvalid",
		"checkboxLabel",
		"labelSuffix",
	]);
	input_props.className = `checkbox${className ? ` ${className}` : ""}`;

	useEffect(() => {
		setValidationError(error);
	}, [error]);

	return (
		<>
			<div className={groupWrapperClassName.join(" ")}>
				<div className="label_wrapper">
					{label !== false ? <p className="field_label">{label}</p> : null}
					{validationError ? <p className="validation_error">{validationError}</p> : null}
				</div>
				<div className="input_wrapper">
					<label htmlFor={id} className={wrapperClassName.join(" ")}>
						<input
							type="checkbox"
							{...input_props}
							onChange={(event) => setValidationError(isInvalid(event.target.checked))}
						/>
						<Checkbox />
						<span>{checkboxLabel}</span>
					</label>
				</div>
			</div>
		</>
	);
}

export function Honeypot() {
	return (
		<label className="honey_im_home">
			Leave this empty: <input type="text" name="url" autoComplete="false" />
		</label>
	);
}

interface HiddenInputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
}

export let HiddenInput = React.forwardRef<HTMLInputElement, HiddenInputProps>((props) => {
	return (
		<>
			<input type="hidden"  {...props}  />
		</>
	);
});
