import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import DocumentMeta from "react-document-meta";
import {Input, Submit, HiddenInput} from "../components/FormInputs";
import {User} from "../svg";
// @ts-ignore
import { is_user_authenticated } from "../models/User";
import { metaDataT } from "../types/GlobeType";

export let meta = (data: any) => {
	let meta_object: metaDataT = {};
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};

const IsEmpty = (field: string) => {
	if (field === "") {
		return "This field is required.";
	}
	return false;
}

const ForgottenPassword = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false)
	const [auth_errors, setAuth_errors] = useState<{ error?: { message: string } }>({})

	const handleOnSubmit = async (event:any) => {
		event.preventDefault()
		setLoading(true)
		const formData = new FormData(event.target);
		const res = await fetch(
			`${process.env.REACT_APP_WORDPRESS_API_URL}?rest_route=/simple-jwt-login/v1/user/reset_password&email=${formData.get('email')}&domain=${formData.get('domain')}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			}
		)
			.then((response) => {
				setLoading(false)
				return response.json()
			})
			.catch((error) => {
				setLoading(false)
				return error.json()
			});
		if(res.success) {
			navigate("/forgotten-password-check-email");
		}
		else {
			setAuth_errors({ error: { message: res.data.message }});
		}
	}
	return (
		<DocumentMeta >
			<main className="basic_page">
				<header className="common_page_header">
					<h1 className="page_title">Forgotten password</h1>
				</header>
				<div className="basic_page_content">
					<form method="post" className="login_form" onSubmit={handleOnSubmit}>
						{auth_errors.error ? <p className="login_error_message">{auth_errors.error?.message}</p> : null}
						<Input
							name="email"
							label="Email address"
							required={true}
							className="single_line_input username_or_email_field password"
							id="email_field"
							leadingIcon={<User />}
							isInvalid={IsEmpty}
							autoComplete="username"
						/>
						<HiddenInput
							name="domain"
							value={window.location.origin}
						/>

						<Submit className="button password_submit">{loading ? "Sending  ..." : "Reset"}</Submit>
					</form>
					<p className="register_note" >Don´t have an account yet?      <Link to="/register" >Register now</Link></p>
					<p className="register_note"><Link to="/login">Go back to login</Link></p>
				</div>
			</main>
		</DocumentMeta>
	);
}

export default ForgottenPassword
