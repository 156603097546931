import {NavLink, useNavigate, useParams} from "react-router-dom";
import {User as UserSvg, Favourite, Checklist} from "../svg";

import {User} from "../types/GlobeType";
import React, {useEffect} from "react";
import Details from "./my-account/details";
import Favourites from "./my-account/favourites";
import MyChecklists from "./my-account/checklists";
import Delete from "./my-account/delete";
import {redirectUnauthenticatedToLoginAndReturnAfter} from "../models/User";
import Loader from "../components/Loader";

export default function MyAccount({user, token}: { user: false | User | undefined; token: false | string }) {
    const navigate = useNavigate();
    const params = useParams()

    useEffect(() => {
        if (!token) {
            redirectUnauthenticatedToLoginAndReturnAfter('/my-account/details', user, navigate)
        }
    }, [token]);

    useEffect(() => {
        window.scrollTo({ left: 0, top: 0})
    }, []);

    if (!user) {
        navigate("/login");
        return <Loader />
    }
    const handleOnSubmit = () => {
        navigate("/logout");
    }
    if (!params.children) {
        navigate("/my-account/details");
        return <Loader />
    }

    let user_display_name;
    if (user.first_name !== "" && user.last_name !== "") {
        user_display_name = `${user.first_name} ${user.last_name}`;
    } else if (user.first_name !== "") {
        user_display_name = user.first_name;
    } else {
        user_display_name = user.last_name;
    }
    return (
        <div className="site_container my_account_container">
            <header className="my_account_header">
                <div className="my_account_user_info">
                    <p>
                        <span className="display_name">{user_display_name}</span>
                        <span className="username">{user.username}</span>
                    </p>
                    <p>
                        {user.meta.organisation ? `${user.meta.organisation} / ` : ""}
                        {user.email}
                    </p>
                </div>
                <form method="post" onSubmit={handleOnSubmit}>
                    <button type="submit" className="button outline logout_button">
                        Logout
                    </button>
                </form>
            </header>
            <nav className="my_account_nav">
                <NavLink
                    to="/my-account/details"
                    className={({isActive}) => `my_account_nav_link${isActive ? " active" : ""}`}
                >
                    <UserSvg/> Account details
                </NavLink>
                <NavLink
                    to="/my-account/favourites"
                    className={({isActive}) => `my_account_nav_link${isActive ? " active" : ""}`}
                >
                    <Favourite/> Favourites
                </NavLink>
                <NavLink
                    to="/my-account/checklists"
                    className={({isActive}) => `my_account_nav_link${isActive ? " active" : ""}`}
                >
                    <Checklist/> Saved checklists
                </NavLink>
            </nav>
            {(params.children === 'details' || !params.children) && <Details user={user} token={token}/>}
            {params.children === 'favourites' && <Favourites user={user} token={token}/>}
            {params.children === 'checklists' && <MyChecklists user={user} token={token}/>}
            {params.children === 'delete' && <Delete user={user} token={token}/>}
        </div>
    );
}
