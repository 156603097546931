import React from 'react'
import { Facebook, Twitter, LinkedIn } from "../svg";

export function SocialBar() {
	return (
		<div className="social_links">
			<a href="#" aria-label="Link to our Facebook page.">
				<Facebook />
			</a>
			<a href="#" aria-label="Link to our Twitter page.">
				<Twitter />
			</a>
			<a href="#" aria-label="Link to our LinkedIn page.">
				<LinkedIn />
			</a>
		</div>
	);
}
