import React from "react";
import {Link} from "react-router-dom";
import DocumentMeta from "react-document-meta";

// @ts-ignore
import { is_user_authenticated } from "../models/User";
import { metaDataT } from "../types/GlobeType";

export let meta = (data: any) => {
	let meta_object: metaDataT = {};
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};


const ForgottenPasswordCheckEmail = () => {
	return (
		<DocumentMeta >
			<main className="basic_page">
				<header className="common_page_header">
					<h1 className="page_title">Check your mail</h1>
				</header>
				<div className="basic_page_content password_content">

					<p>We have sent instructions on how to reset your password to your email address.</p>
					<p>Don´t have an account yet? <Link to="/register" >Register now</Link></p>

				</div>
			</main>
		</DocumentMeta>
	);
}

export default ForgottenPasswordCheckEmail
