import {useEffect, useState} from "react";

export const useLoaderData: any = (initialUrl: string, initialData: { post?: {}; the_post?: {}; about_content?: { icon_sections: never[]; quote_section: {}; }; action_data?: { errors: {}; values: {}; } | {}; support_data?: never[] | { title: string; search: string; accordions: never[]; }; saved_checklists?: never[]; favourited_posts?: { sections: {}; }; }, headers: any) => {
	const [state, dispatch] = useState(initialData);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		let didCancel = false;

		const fetchData = async () => {

			try {
				const result = await( await fetch(initialUrl, {
					headers,
				})).json();

				if (!didCancel && result?.success !== false) {
					dispatch(result);
					setIsLoading(false);
				}
			} catch (error) {
				console.log('error',error)
				setIsLoading(false);
				return null
			}
		};

		fetchData();

		return () => {
			didCancel = true;
		};
	}, [initialUrl]);

	return [state, isLoading];
};
