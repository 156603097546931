import {CommonPageHeader} from "../components/CommonPageHeader";
import type {WP_Post} from "wp-types";

import {useLoaderData} from "../hooks/useLoaderData";
import {metaDataT, SeoDataT, User} from "../types/GlobeType";
import React from "react";
import {useParams} from "react-router-dom";
import DocumentMeta from "react-document-meta";
import Loader from "../components/Loader";


export let meta = (data: any) => {
    let meta_object: metaDataT = {};
    if (data?.seo?.title) {
        meta_object.title = data.seo.title;
    }
    if (data?.seo?.description) {
        meta_object.description = data.seo.description;
    }
    if (data?.seo?.robots) {
        meta_object.robots = Object.values(data.seo.robots).join(", ");
    }
    return meta_object;
};

// @ts-ignore
export default function MyAccount({user}: { user: false | User | undefined }) {
    // eslint-disable-next-line no-restricted-globals
    const preview = new URLSearchParams(location.search).get('preview') ?? false;
    const params = useParams()

    const [postData, isLoading] = useLoaderData(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_post_by_url/${params.slug}`, preview && preview === "true" ? {
        post: {}
    } : {
        the_post: {}
    })

    if (isLoading) {
        return <Loader />
    }

    // @ts-ignore
    let {the_post}: { the_post: WP_Post } = postData
    return (
        <DocumentMeta {...meta(postData)}>
            <main className="basic_page">
                {the_post ?
                    <><CommonPageHeader title={the_post.post_title}/>
                    <div className="basic_page_content" dangerouslySetInnerHTML={{__html: the_post.post_content}}></div></>
                    : <h1>404</h1>}
            </main>
        </DocumentMeta>
    );
}
