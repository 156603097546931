import zxcvbn from "zxcvbn";
import { contact_reason_for_contact_options, organisation_role_options } from "../models/Data";

export class Validate {
	static generic_is_required(field: string) {
		if (field === "") {
			return "This field is required.";
		}
		return false;
	}
	static is_username_field_invalid(username: string) {
		if (username === "") {
			return "Username is required.";
		}
		return false;
	}
	static is_email_field_invalid(email_address: string) {
		if (email_address === "") {
			return "Email is required.";
		} else if (!/\S+@\S+\.\S+/.test(email_address)) {
			return "Email is not valid.";
		}
		return false;
	}
	static is_register_password_field_invalid(password: string) {
		if (password === "") {
			return "Password is required.";
		} else if (zxcvbn(password).score <= 2) {
			return "You must use a stronger password.";
		}
		return false;
	}
	static is_my_account_password_field_invalid(password: string) {
		if (password !== "" && zxcvbn(password).score <= 2) {
			return "You must use a stronger password.";
		}
		return false;
	}
	static is_first_name_field_invalid(first_name: string) {
		if (first_name === "") {
			return "First name is required.";
		}
		return false;
	}
	static is_last_name_field_invalid(last_name: string) {
		if (last_name === "") {
			return "Last name is required.";
		}
		return false;
	}
	static is_organisation_field_invalid(organisation: string) {
		if (organisation === "") {
			return "Organisation is required.";
		}
		return false;
	}
	static is_organisation_role_field_invalid(organisation_role: string) {
		if (organisation_role === "") {
			return "Organisation role is required.";
		} else { // @ts-ignore
			if (!organisation_role_options.find((role: { value: any; label: any; }) => organisation_role === (role.value ?? role.label))) {
						return "Organisation role is invalid.";
					}
		}
		return false;
	}
	static is_data_privacy_checkbox_invalid(data_privacy: string) {
		if (!data_privacy) {
			return "You must agree to the privacy policy.";
		}
		return false;
	}
	static is_terms_checkbox_invalid(terms: string) {
		if (!terms) {
			return "You must agree to the terms and conditions.";
		}
		return false;
	}
	static is_name_field_invalid(name: string) {
		if (name === "") {
			return "Name is required.";
		}
		return false;
	}
	static is_reason_for_contact_field_invalid(reason_for_contact: string) {
		if (reason_for_contact === "") {
			return "Reason for contact is required.";
		} else {
			if (// @ts-ignore
						!contact_reason_for_contact_options.find((reason: { value: any; label: any; }) => reason_for_contact === (reason.value ?? reason.label))
					) {
						return "Reason for contact is invalid.";
					}
		}
		return false;
	}
	static is_subject_field_invalid(subject: string) {
		if (subject === "") {
			return "Subject is required.";
		}
		return false;
	}
	static is_message_field_invalid(message: string) {
		if (message === "") {
			return "Message is required.";
		}
		return false;
	}
}

interface RegisterValidationErrors {
	exist: boolean;
	username?: string;
	email_address?: string;
	password?: string;
	first_name?: string;
	last_name?: string;
	organisation?: string;
	organisation_role?: string;
	data_privacy_group?: string;
	terms_group?: string;
}

export function validateRegisterForm(formData: FormData) {
	let errors: RegisterValidationErrors = { exist: false };

	let email_address = (formData.get("email_address") as string) ?? "";
	let email_errors = Validate.is_email_field_invalid(email_address);
	if (email_errors) {
		errors.exist = true;
		errors.email_address = email_errors;
	}

	let password = (formData.get("password") as string) ?? "";
	let password_errors = Validate.is_register_password_field_invalid(password);
	if (password_errors) {
		errors.exist = true;
		errors.password = password_errors;
	}

	let first_name = (formData.get("first_name") as string) ?? "";
	let first_name_errors = Validate.is_first_name_field_invalid(first_name);
	if (first_name_errors) {
		errors.exist = true;
		errors.first_name = first_name_errors;
	}

	let last_name = (formData.get("last_name") as string) ?? "";
	let last_name_errors = Validate.is_last_name_field_invalid(last_name);
	if (last_name_errors) {
		errors.exist = true;
		errors.last_name = last_name_errors;
	}

	let organisation = (formData.get("organisation") as string) ?? "";
	let organisation_errors = Validate.is_organisation_field_invalid(organisation);
	if (organisation_errors) {
		errors.exist = true;
		errors.organisation = organisation_errors;
	}

	let organisation_role = (formData.get("organisation_role") as string) ?? "";
	let organisation_role_errors = Validate.is_organisation_role_field_invalid(organisation_role);
	if (organisation_role_errors) {
		errors.exist = true;
		errors.organisation_role = organisation_role_errors;
	}

	let data_privacy = (formData.get("data_privacy") as string) ?? "";
	let data_privacy_errors = Validate.is_data_privacy_checkbox_invalid(data_privacy);
	if (data_privacy_errors) {
		errors.exist = true;
		errors.data_privacy_group = data_privacy_errors;
	}

	let terms = (formData.get("terms") as string) ?? "";
	let terms_errors = Validate.is_terms_checkbox_invalid(terms);
	if (terms_errors) {
		errors.exist = true;
		errors.terms_group = terms_errors;
	}
	return errors;
}

interface MyAccountValidationErrors {
	exist: boolean;
	email_address?: string;
	password?: string;
	first_name?: string;
	last_name?: string;
	organisation?: string;
	organisation_role?: string;
}

export function validateMyAccountForm(formData: FormData) {
	let errors: MyAccountValidationErrors = { exist: false };

	let email_address = (formData.get("email_address") as string) ?? "";
	let email_errors = Validate.is_email_field_invalid(email_address);
	if (email_errors) {
		errors.exist = true;
		errors.email_address = email_errors;
	}

	let password = (formData.get("password") as string) ?? "";
	let password_errors = Validate.is_my_account_password_field_invalid(password);
	if (password_errors) {
		errors.exist = true;
		errors.password = password_errors;
	}

	let first_name = (formData.get("first_name") as string) ?? "";
	let first_name_errors = Validate.is_first_name_field_invalid(first_name);
	if (first_name_errors) {
		errors.exist = true;
		errors.first_name = first_name_errors;
	}

	let last_name = (formData.get("last_name") as string) ?? "";
	let last_name_errors = Validate.is_last_name_field_invalid(last_name);
	if (last_name_errors) {
		errors.exist = true;
		errors.last_name = last_name_errors;
	}

	let organisation = (formData.get("organisation") as string) ?? "";
	let organisation_errors = Validate.is_organisation_field_invalid(organisation);
	if (organisation_errors) {
		errors.exist = true;
		errors.organisation = organisation_errors;
	}

	let organisation_role = (formData.get("organisation_role") as string) ?? "";
	let organisation_role_errors = Validate.is_organisation_role_field_invalid(organisation_role);
	if (organisation_role_errors) {
		errors.exist = true;
		errors.organisation_role = organisation_role_errors;
	}
	return errors;
}

interface ContactValidationErrors {
	exist: boolean;
	full_name?: string;
	email_address?: string;
	reason_for_contact?: string;
	subject?: string;
	message?: string;
	data_privacy_group?: string;
	terms_group?: string;
}
export function validateContactForm(formData: FormData) {
	let errors: ContactValidationErrors = { exist: false };

	let full_name = (formData.get("full_name") as string) ?? "";
	let full_name_errors = Validate.is_name_field_invalid(full_name);
	if (full_name_errors) {
		errors.exist = true;
		errors.full_name = full_name_errors;
	}

	let email_address = (formData.get("email_address") as string) ?? "";
	let email_errors = Validate.is_email_field_invalid(email_address);
	if (email_errors) {
		errors.exist = true;
		errors.email_address = email_errors;
	}

	let reason_for_contact = (formData.get("reason_for_contact") as string) ?? "";
	let reason_for_contact_errors = Validate.is_reason_for_contact_field_invalid(reason_for_contact);
	if (reason_for_contact_errors) {
		errors.exist = true;
		errors.reason_for_contact = reason_for_contact_errors;
	}

	let subject = (formData.get("subject") as string) ?? "";
	let subject_errors = Validate.is_subject_field_invalid(subject);
	if (subject_errors) {
		errors.exist = true;
		errors.subject = subject_errors;
	}
	let message = (formData.get("message") as string) ?? "";
	let message_errors = Validate.is_message_field_invalid(message);
	if (message_errors) {
		errors.exist = true;
		errors.message = message_errors;
	}

	let data_privacy = (formData.get("data_privacy") as string) ?? "";
	let data_privacy_errors = Validate.is_data_privacy_checkbox_invalid(data_privacy);
	if (data_privacy_errors) {
		errors.exist = true;
		errors.data_privacy_group = data_privacy_errors;
	}

	let terms = (formData.get("terms") as string) ?? "";
	let terms_errors = Validate.is_terms_checkbox_invalid(terms);
	if (terms_errors) {
		errors.exist = true;
		errors.terms_group = terms_errors;
	}

	return errors;
}

interface NewPasswordValidationErrors {
	exist: boolean;
	password?: string;
	confirm_password?: string;
	message?: string;
}

export function NewPasswordValidationErrors(formData: FormData) {
	let errors: NewPasswordValidationErrors = {exist: false };


	let password = (formData.get("password") as string) ?? "";
	let password_errors = Validate.is_register_password_field_invalid(password);
	if (password_errors) {
		errors.exist = true;
		errors.password = password_errors;
	}

	let confirm_password = (formData.get("confirm_password") as string) ?? "";
	let confirm_password_errors = Validate.is_register_password_field_invalid(confirm_password);
	if (confirm_password_errors) {
		errors.exist = true;
		errors.confirm_password = confirm_password_errors;
	}

	if (password.length > 0 && confirm_password.length > 0 && password !== confirm_password) {
		errors.exist = true;
		errors.message = "Passwords are not the same.";
	}


	return errors;
}

interface MailchimpValidationErrors {
	exist: boolean;
	email_address?: string;
}
export function validateMailchimpForm(formData: FormData) {
	let errors: MailchimpValidationErrors = { exist: false };
	let email_address = (formData.get("email_address") as string) ?? "";
	let email_errors = Validate.is_email_field_invalid(email_address);
	if (email_errors) {
		errors.exist = true;
		errors.email_address = email_errors;
	}

	return errors;
}