import React, { useRef, useEffect } from "react";
import { Input } from "./FormInputs";
import { Search, SearchShortcut } from "../svg";


export function CommonPageHeaderWithSearch({
	title,
	search,
	search_value,
	set_search_state,
}: {
	title: string;
	search: string;
	search_value?: string;
	set_search_state: React.Dispatch<React.SetStateAction<string>>;
}) {
	const searchbar_input_ref = useRef<HTMLInputElement>(null);
	useEffect(() => {
		document.addEventListener("keyup", function (event) {
			if (event.isComposing || event.keyCode === 229) {
				return;
			}
			if (event.code !== "Slash") {
				return;
			} else {
				searchbar_input_ref.current?.focus();
			}
		});
	}, []);

	return (
		<header className="common_page_header has_search">
			<h1 className="page_title">{title}</h1>
			<form method="get" id="search_form">
				<label htmlFor="page_search" className="screen_reader_only">
					{search}
				</label>
				<Input
					type="text"
					className="search_input"
					id="page_search"
					ref={searchbar_input_ref}
					label={false}
					name="search"
					isInvalid={() => false}
					placeholder={search}
					leadingIcon={<Search />}
					trailingIcon={<SearchShortcut />}
					defaultValue={search_value}
					onChange={(event) => set_search_state(event.target.value)}
				/>
			</form>
		</header>
	);
}
