interface HasTitleProperty {
	title: string;
	[key: string]: any;
}
function sort_by_title(a: HasTitleProperty, b: HasTitleProperty) {
	let titleA = a.title.toUpperCase();
	let titleB = b.title.toUpperCase();
	if (titleA < titleB) {
		return -1;
	}
	if (titleA > titleB) {
		return 1;
	}

	// Titles must be equal
	return 0;
}
interface HasScoreProperty {
	score: number;
	[key: string]: any;
}
function sort_by_score(a: HasScoreProperty, b: HasScoreProperty) {
	return a.score - b.score;
}

export function get_support_accordions_search_results(
	accordions: SupportData["accordions"],
	search_term: string,
	sort_by: "relevance" | "title" = "relevance",
	sort: "asc" | "desc" = "desc"
) {
	search_term = search_term.toLowerCase();
	let valid_search_data: SupportData["accordions"] = [];
	let search_term_pieces = search_term.split(" ");
	let sorted_array: SupportData["accordions"] = [];
	if (sort_by === "title") {
		valid_search_data = accordions.filter((accordion) => {
			let title = accordion.title.toLowerCase();
			let content = accordion.content.toLowerCase();
			if (title.includes(search_term) || content.includes(search_term)) {
				return true;
			}
			for (let search_term_piece of search_term_pieces) {
				if (title.includes(search_term_piece) || content.includes(search_term_piece)) {
					return true;
				}
			}
			return false;
		});
		sorted_array = valid_search_data.sort(sort_by_title);
	} else {
		let accordions_with_scores: { score: number; accordion: Accordion }[] = [];
		for (let accordion of accordions) {
			let accordion_search_score = 0;
			let title = accordion.title.toLowerCase();
			let content = accordion.content
				.toLowerCase()
				.replace(/<[^>]*>?/gm, "") // remove HTML tags
				.replace(/(\r\n|\n|\r)/gm, ""); // remove line breaks

			if (
				title.includes(` ${search_term} `) ||
				title.startsWith(`${search_term} `) ||
				title.endsWith(` ${search_term}`)
			) {
				accordion_search_score = accordion_search_score + 500;
			}
			if (
				content.includes(` ${search_term} `) ||
				content.startsWith(`${search_term} `) ||
				content.endsWith(` ${search_term}`)
			) {
				accordion_search_score = accordion_search_score + 450;
			}

			if (accordion_search_score === 0) {
				if (title.includes(search_term)) {
					accordion_search_score = accordion_search_score + 100;
				}
				if (content.includes(search_term)) {
					accordion_search_score = accordion_search_score + 90;
				}
			}

			if (accordion_search_score === 0) {
				for (let search_term_piece of search_term_pieces) {
					if (title.includes(search_term_piece)) {
						accordion_search_score = accordion_search_score + 5;
					}
					if (content.includes(search_term_piece)) {
						accordion_search_score = accordion_search_score + 1;
					}
				}
			}
			accordions_with_scores.push({
				score: accordion_search_score,
				accordion,
			});
		}

		let accordions_sorted_by_score = accordions_with_scores.sort(sort_by_score);

		for (let accordions_with_score of accordions_sorted_by_score) {
			if (accordions_with_score.score > 0) {
				sorted_array.push(accordions_with_score.accordion);
			}
		}
	}

	if (sort === "desc") {
		return sorted_array.reverse();
	}
	return sorted_array;
}

export function get_checklist_search_results(
	checklists: ChecklistsData["checklists"],
	search_term: string,
	sort_by: "relevance" | "title" = "relevance",
	sort: "asc" | "desc" = "desc"
) {
	search_term = search_term.toLowerCase();
	let valid_search_data: ChecklistsData["checklists"] = [];
	let search_term_pieces = search_term.split(" ");
	let sorted_array: ChecklistsData["checklists"] = [];
	if (sort_by === "title") {
		valid_search_data = checklists.filter((checklist) => {
			let title = checklist.title.toLowerCase();
			if (title.includes(search_term)) {
				return true;
			}
			for (let search_term_piece of search_term_pieces) {
				if (title.includes(search_term_piece)) {
					return true;
				}
			}
			return false;
		});
		sorted_array = valid_search_data.sort(sort_by_title);
	} else {
		let checklists_with_scores: { score: number; checklist: ArchiveChecklistData }[] = [];
		for (let checklist of checklists) {
			let checklist_search_score = 0;
			let title = checklist.title.toLowerCase();

			if (
				title.includes(` ${search_term} `) ||
				title.startsWith(`${search_term} `) ||
				title.endsWith(` ${search_term}`)
			) {
				checklist_search_score = checklist_search_score + 500;
			}

			if (checklist_search_score === 0) {
				if (title.includes(search_term)) {
					checklist_search_score = checklist_search_score + 100;
				}
			}

			if (checklist_search_score === 0) {
				for (let search_term_piece of search_term_pieces) {
					if (title.includes(search_term_piece)) {
						checklist_search_score = checklist_search_score + 5;
					}
				}
			}
			checklists_with_scores.push({
				score: checklist_search_score,
				checklist,
			});
		}

		let checklists_sorted_by_score = checklists_with_scores.sort(sort_by_score);

		for (let checklists_with_score of checklists_sorted_by_score) {
			if (checklists_with_score.score > 0) {
				sorted_array.push(checklists_with_score.checklist);
			}
		}
	}

	if (sort === "desc") {
		return sorted_array.reverse();
	}
	return sorted_array;
}

export function get_docs_sections_search_results(sections: DocsPostSection[], search_term: string) {
	if (search_term === "") {
		return [];
	}
	let searched_sections: SubsectionData[] = [];
	search_term = search_term.toLowerCase();
	let search_term_pieces = search_term.split(" ");

	for (let section of sections) {
		let posts = section.posts;
		for (let post of posts) {
			let include_post = false;
			let title = post.post_title.toLowerCase();

			if (title.includes(search_term)) {
				include_post = true;
			}
			for (let search_term_piece of search_term_pieces) {
				if (title.includes(search_term_piece)) {
					include_post = true;
				}
			}

			if (include_post) {
				let search_post = post;
				search_post.section_title = section.title;
				searched_sections.push(search_post);
			}
		}
	}

	return searched_sections.slice(0, 5);
}
