import React, {useState} from 'react'
import { update_current_user } from "../../models/User";
import { Favourite } from "../../svg";
import { User } from "../../types/GlobeType";

function favourite_or_unfavourite_section(user: User, section: number, token: string) {
	const before_update_user = JSON.parse(JSON.stringify(user));

	if (user.meta.favourite_sections.includes(section)) {
		user.meta.favourite_sections = user.meta.favourite_sections.filter(function (array_value: number) {
			return array_value !== section;
		});
	} else {
		user.meta.favourite_sections.push(section);
	}
	update_current_user(
		before_update_user,
		{
			meta: {
				favourite_sections: user.meta.favourite_sections,
			},
		},
		token
	);
	return user;
}

// @ts-ignore
export let action = (user, section, token) => {
	if (section) {
		return favourite_or_unfavourite_section(user, Number(section), token);
	}
	return user;
};

export function AddToFavourites({ id, user, token }: { id: number; user: false | User | undefined; token: string }) {
	const [localUser, setLocalUser] = useState(user)
	const [favourite, setFavourite] = useState({
		state: '',
		type: ''
	})

	const handleOnSubmit = (event: any) => {
		event.preventDefault()
		const uUser = action(localUser, id, token)
		setLocalUser(uUser)
		setFavourite(uUser.meta.favourite_sections.includes(id) ? {
			state: 'checked',
			type: ''
		} : {
			state: '',
			type: ''
		})
	}

	if (!localUser) {
		return null
	}

	let isFavourite = localUser.meta.favourite_sections.includes(id);

	return (
		<button
			type="submit"
			className={`section_quick_action_button${favourite.state === "submitting" ? " submitting" : ""}${
				isFavourite
					? " checked"
					: ""
			}`}
			aria-label="Favourite this section."
			onClick={handleOnSubmit}
		>
			<Favourite />
		</button>
	);
}
