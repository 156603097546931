import React, { useState, useEffect, BaseSyntheticEvent } from "react";
import {NavLink, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";

import { SubSection} from "../components/SubSection";
import { NeedHelp } from "../components/NeedHelp";
import { Cross, DropdownThick, Navigate, Search, SearchShortcut } from "../svg";
import { redirectUnauthenticatedToLoginAndReturnAfter } from "../models/User";
import { get_docs_sections_search_results } from "../models/Search";
import { slugify } from "../models/Slugify";
import { pushSectionToHistory, scrollToNewSection } from "../models/DocsScroll";

import {metaDataT, User} from "../types/GlobeType";
import DocumentMeta from "react-document-meta";
import Loader from "../components/Loader";

export const handle = { disableScrollRestoration: true };

export let meta = (data: any) => {
	let meta_object: metaDataT = {};
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};

async function get_docs_content(JWT: string) {
	return fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_docs_posts`, {
		headers: {
			Authorization: `Bearer ${JWT}`,
		},
	})
		.then(async (response) => {
			if (response.status !== 200) {
				throw JSON.stringify( { status: response.status,response});
			}
			return response;
		})
		.then((response) => response.json());
}
function was_rejected(promise_result: PromiseSettledResult<any>): promise_result is PromiseRejectedResult {
	return promise_result.status === "rejected";
}

export const getData = async (JWT:string) => {
	return new Promise(async (resolve, reject) => {
			try {
				resolve(await get_docs_content(JWT));
			} catch (response) {
				reject(response);
			}
	})
}

const getPosts = async (docs_posts_data: Promise<unknown>, userData: Promise<User> ) => {
	return Promise.allSettled([docs_posts_data, userData])
		.then((results) => {
			if (was_rejected(results[0])) {
				console.error({ response: results[0].reason });
				throw new Error("Failed to load content.");
			}
			if (was_rejected(results[1])) {
				console.error({ response: results[1].reason });
				throw new Error("Failed to load user.");
			}

			return {
				docs_posts: results[0].value,
				user: results[1].value,
			};
		})
		.catch((error) => {
			console.error(error);
			throw new Error(error.message);
		});
}


export default function Index({ user, token }: { user: false | User | undefined; token: string }) {
	// eslint-disable-next-line no-restricted-globals
	history.scrollRestoration = "manual"
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams()
	// @ts-ignore
	let { section, subsection, gallery_or_print }: { section: string, subsection: string, gallery_or_print: string } = params;
	let [activeSubmenu, setActiveSubmenu] = useState('');
	let [isLoading, setIsLoading] = useState(true);
	let [show_searchbar, setShowSearchbar] = useState(false);
	let [isInitialLoad, setIsInitialLoad] = useState(true);
	let [current_section_title, setCurrentSectionTitle] = useState("");
	let [currentParams, setCurrentParams] = useState({
		section,
		subsection,
	});
	let [search_state, set_search_state] = useState("");
	let [selectedResultIndex, setSelectedResultIndex] = useState(0);

	const [searchParams, setSearchParams] = useSearchParams();
	let querySearch =searchParams.get("search")


	if(querySearch && querySearch.length>0) {
		navigate("/documentation");
	}

	const [data, setData] = useState({
		docs_posts :{
			menu_items: [],
			sections: []
		},
		user: {}
	});

	useEffect(() => {
		setCurrentParams(params as any)
		scrollToNewSection(params as any, null);
	}, [params, data])

	useEffect(() => {
		if (token) {
			// @ts-ignore
			getData(token).then((docPostData) => {
				// @ts-ignore
				getPosts(docPostData, user).then((posts) => {
					// @ts-ignore
					setData(posts)
					setIsLoading(false)
				})
			})
		} else {
			redirectUnauthenticatedToLoginAndReturnAfter(location.pathname, user, navigate)
		}
	}, [token]);

	// @ts-ignore
	let { docs_posts }: { docs_posts: DocsPosts } = data
	let { menu_items, sections } = docs_posts;

	let search_sections: SubsectionData[] = get_docs_sections_search_results(sections, search_state);

	function focusOnSearchItem(index: number) {
		let all_results: NodeListOf<HTMLElement> | undefined = document
			.getElementById("docs_search_results")
			?.querySelectorAll("docs_search_result");
		if (all_results) {
			// @ts-ignore
			for (let result of all_results) {
				result.classList.remove("selected");
				if (result.dataset.result_index === index.toString()) {
					result.classList.add("selected");
				}
			}
		}
	}

	useEffect(() => {
		search_sections = get_docs_sections_search_results(sections, search_state);
		setSelectedResultIndex(0);
		focusOnSearchItem(0);
	}, [search_state]);

	useEffect(() => {
		setCurrentParams({
			section,
			subsection,
		});
		scrollToNewSection({ section, subsection }, null, "auto");
		setIsInitialLoad(false);

		document.addEventListener("keydown", function (event) {
			if (event.isComposing || event.keyCode === 229) {
				return;
			}
			let isSearchPopupVisible = document.getElementsByClassName("docs_search_popup")[0] ? document.getElementsByClassName("docs_search_popup")[0].classList.contains("show") : false;
			if (
				(event.code === "Slash" && !isSearchPopupVisible) ||
				(event.code === "Escape" && isSearchPopupVisible) ||
				(event.code === "Enter" && isSearchPopupVisible) ||
				(event.code === "ArrowUp" && isSearchPopupVisible) ||
				(event.code === "ArrowDown" && isSearchPopupVisible)
			) {
				event.preventDefault();
			}
		});
		document.addEventListener("keyup", function (event) {
			if (event.isComposing || event.keyCode === 229) {
				return;
			}
			let isSearchPopupVisible = document.getElementsByClassName("docs_search_popup")[0] ? document.getElementsByClassName("docs_search_popup")[0].classList.contains("show") : false;
			if (event.code === "Slash" && !isSearchPopupVisible) {
				openSearchPopup();
			}
			if (event.code === "Escape" && isSearchPopupVisible) {
				closeSearchPopup();
			}
			if (event.code === "Enter" && isSearchPopupVisible) {
				event.preventDefault();
				let index_attribute = document
					.getElementById("docs_search_results")
					?.getAttribute("data-selected_result_index");
				if (!index_attribute) {
					console.error("Missing attribute");
					return;
				}
				let searched_section = document.querySelector(`.docs_search_result[data-result_index="${index_attribute}"]`);
				if (!searched_section) {
					console.error("Searched section missing");
					return;
				}
				let section = searched_section.getAttribute("data-section") ?? "";
				let subsection = searched_section.getAttribute("data-subsection") ?? "";
				closeSearchPopup();
				scrollToNewSection({
					section,
					subsection,
				});
			}
			if (event.code === "ArrowUp" && isSearchPopupVisible) {
				event.preventDefault();
				let index_attribute = document
					.getElementById("docs_search_results")
					?.getAttribute("data-selected_result_index");
				if (!index_attribute) {
					console.error("Missing attribute");
					return;
				}
				let index = Number(index_attribute) - 1;
				if (index <= -1) {
					setSelectedResultIndex(4);
					return;
				}
				setSelectedResultIndex(index);
			}
			if (event.code === "ArrowDown" && isSearchPopupVisible) {
				event.preventDefault();
				let index_attribute = document
					.getElementById("docs_search_results")
					?.getAttribute("data-selected_result_index");
				if (!index_attribute) {
					console.error("Missing attribute");
					return;
				}
				let index = Number(index_attribute) + 1;
				if (index >= 5) {
					setSelectedResultIndex(0);
					return;
				}
				setSelectedResultIndex(index);
			}
		});
	}, []);

	useEffect(() => {
		if (gallery_or_print === "print" && data?.docs_posts) {
			setTimeout(() => {
				window.print();
				navigate(location.pathname.replace('/print', ''))
			}, 200)
		}
	}, [gallery_or_print, data, location, navigate]);

	useEffect(() => {
		if (Array.isArray(data?.docs_posts?.menu_items) && data?.docs_posts?.menu_items.length > 0) {
			if (!currentParams?.section) {
				// @ts-ignore
				setCurrentSectionTitle(data?.docs_posts?.menu_items[0]?.name)
			} else {
				// @ts-ignore
				setCurrentSectionTitle(data?.docs_posts?.menu_items.find(sec => sec.url === currentParams.section)?.name)
			}
		}
	}, [data, currentParams]);

	function toggle_docs_submenu(id: string) {
		setActiveSubmenu(activeSubmenu === id ? '' : id)
	}

	function close_menu_subnav() {
		setActiveSubmenu("")
	}

	function openSearchPopup() {
		document.getElementsByClassName("docs_search_popup")[0].classList.add("show");
		focusDocsSearchInput();
		setShowSearchbar(false);
	}
	function closeSearchPopup() {
		document.getElementsByClassName("docs_search_popup")[0].classList.remove("show");
		clearDocsSearchInput();
	}
	function focusDocsSearchInput() {
		let search_input = document.getElementById("docs_search")
			? (document.getElementById("docs_search") as HTMLInputElement)
			: null;
		if (search_input) {
			search_input.focus();
		}
	}
	function clearDocsSearchInput() {
		let search_input = document.getElementById("docs_search")
			? (document.getElementById("docs_search") as HTMLInputElement)
			: null;
		if (search_input) {
			search_input.value = "";
		}
		set_search_state("");
	}

	let isScrolling: any;

	window.addEventListener('scroll', function ( event ) {

		// Clear our timeout throughout the scroll
		window.clearTimeout( isScrolling );

		// Set a timeout to run after scrolling ends
		isScrolling = setTimeout(function() {
			let el, top, min = Number.MAX_VALUE, els = document.getElementsByClassName('docs_subsection_content');
			for (let i=0; i<els.length; i++) {
				top = Math.abs(els[i].getBoundingClientRect().top);
				if (top < min) {
					min = top;
					el = els[i];
				}
			}
			if (el?.getAttribute("id")) {
				// @ts-ignore
				const [sect, subSect] = el?.getAttribute("id")?.split('_');
				if(sect!==currentParams.section) {
					setCurrentParams({ section: sect, subsection: subSect })
				}
			}
		}, 66);

	}, false);

	if (!user || isLoading) {
		return <Loader />
	}

    return (
		<DocumentMeta {...meta(data?.docs_posts)}>
			<div className="documentation">
				<aside className={`docs_searchbar${show_searchbar ? " show" : ""}`}>
					<button className="docs_search_open_popup" onClick={openSearchPopup}>
						<Search />
						Search documentation
						<SearchShortcut />
					</button>
					<button
						className="show_searchbar_button"
						onClick={() => setShowSearchbar(!show_searchbar)}
						aria-label="Toggle searchbar."
					>
						<DropdownThick />
					</button>
				</aside>
				<nav className="docs_nav">
					<ul>
						{menu_items.map((menu_item) => {
							let is_section = currentParams.section === menu_item.url;
							if (menu_item.children.length > 0) {
								const button_class = is_section ? " current_section" : "";
								return (
									<li key={menu_item.url} className={activeSubmenu === menu_item.url ? 'open' : ''}>
										<button
											className={`docs_nav_item has_children${button_class}`}
											key={menu_item.url}
											onClick={() => { toggle_docs_submenu(menu_item.url) }}
										>
											{menu_item.name}
											<DropdownThick />
										</button>
										<ul className="docs_nav_submenu">
											{menu_item.children.map((sub_menu_item) => {
												let is_subsection =
													currentParams.section === menu_item.url && currentParams.subsection === sub_menu_item.url;
												return (
													<li key={`${menu_item.url}/${sub_menu_item.url}`}>
														<a
															className={`docs_nav_subitem${is_subsection ? " current_sub_section" : ""}`}
															key={`${menu_item.url}/${sub_menu_item.url}`}
															href={`/documentation/${menu_item.url}/${sub_menu_item.url}`}
															dangerouslySetInnerHTML={{ __html: sub_menu_item.name }}
															onClick={(event) => {
																event.preventDefault();
																close_menu_subnav()
																scrollToNewSection({ section: menu_item.url, subsection: sub_menu_item.url });
																pushSectionToHistory({ section: menu_item.url, subsection: sub_menu_item.url }, navigate);
															}}
														></a>
													</li>
												);
											})}
										</ul>
										<div className="docs_nav_overlay" onClick={close_menu_subnav}></div>
									</li>
								);
							} else {
								return (
									<li key={menu_item.url}>
										<NavLink
											className={() => "docs_nav_item" + (is_section ? " current_section" : "")}
											to={menu_item.url}
										>
											{menu_item.name}
										</NavLink>
									</li>
								);
							}
						})}
					</ul>
				</nav>
				<main className="docs_content">
					<h1 className="screen_reader_only">Documentation</h1>
					<div className="docs_sections" style={isInitialLoad ? { visibility: "hidden" } : {}}>
						<header className="docs_section_title screen_reader_only_on_mobile">
							<h2>{current_section_title}</h2>
						</header>
						{sections && sections.length > 0
							? sections.map((the_section) => {
									let is_current_section = the_section.url === currentParams.section;
									let section_classname = ["docs_section"];
									if (is_current_section) {
										section_classname.push("current_section");
                                        section_classname.push("print");
									}
									return (
										<section
											className={section_classname.join(" ")}
											key={the_section.title}
											data-section_title={the_section.title}
										>
											<div className="docs_section_content">
												{the_section.posts
													? the_section.posts.map((post) => {
														let is_current_subsection =
															the_section.url === currentParams.section &&
															post.url === currentParams.subsection;
															return (
																<SubSection
																	subsection_id={post.id}
																	className={
																		is_current_subsection
																			? `current_subsection print`
																			: ""
																	}
																	id={`${the_section.url}_${post.url}`}
																	section={the_section.url || ''}
																	subsection={post.url}
																	key={post.id}
																	subsection_data={post}
																	user={user}
																	token={token}
																/>
															);
													  })
													: null}
											</div>
										</section>
									);
							  })
							: null}
					</div>
				</main>
			</div>
			<NeedHelp />
			<div className={`docs_search_popup`} id="docs_search_popup">
				<div className="docs_search_popup_content">
					<form method="get" className="docs_search_form" onSubmit={(event) => event.preventDefault()}>
						<label htmlFor="docs_search" className="screen_reader_only">
							Search documentation
						</label>
						<Search />
						<input
							type="text"
							name="search"
							placeholder="Search documentation"
							defaultValue={search_state}
							onChange={(event) => set_search_state(event.target.value)}
							className="docs_search_input"
							id="docs_search"
						/>
						<Cross onClick={closeSearchPopup} />
					</form>
					<div
						className={`docs_search_results${search_state !== "" ? " show" : ""}`}
						id="docs_search_results"
						data-selected_result_index={selectedResultIndex}
					>
						{search_sections.length > 0 ? (
							search_sections.map((the_subsection, index) => {
								let section = slugify(the_subsection.section_title ?? "");
								let subsection = the_subsection.url;
								let selected = selectedResultIndex === index;
								return (
									<a
										className={`docs_search_result${selected ? " selected" : ""}`}
										key={`${section}/${subsection}`}
										href={`/documentation/${section}/${subsection}`}
										dangerouslySetInnerHTML={{
											__html: `${the_subsection.sub_section_number} ${the_subsection.post_title}`,
										}}
										onClick={(event) => {
											event.preventDefault();
											closeSearchPopup();
											scrollToNewSection({ section, subsection });
											pushSectionToHistory({
												section,
												subsection,
											}, navigate);
										}}
										data-result_index={index}
										data-section={section}
										data-subsection={subsection}
									></a>
								);
							})
						) : (
							<p className="docs_no_search_result">
								No results were found for this search query, please try searching something else.
							</p>
						)}
					</div>
					<div className="search_keyboard_shortcuts">
						<div className="search_shortcut">
							<span className="screen_reader_only">
								<kbd>Up arrow</kbd>
								<kbd>Down arrow</kbd>
							</span>
							<Navigate /> Navigate
						</div>
						<div className="search_shortcut">
							<kbd>ENTER</kbd> Select page
						</div>
						<div className="search_shortcut">
							<kbd>ESC</kbd> Close search
						</div>
					</div>
				</div>
			</div>
		</DocumentMeta>
	);
}
