import React, {useState} from "react";
import  { useNavigate   } from 'react-router-dom'
import { Input, Submit } from "../../components/FormInputs";
import { redirectUnauthenticatedToLoginAndReturnAfter } from "../../models/User";
import {metaDataT, SeoDataT} from "../../types/GlobeType";
import Loader from "../../components/Loader";

let confirm_text = "delete account";

// @ts-ignore
export let action = async ({ user }, event, token, navigate) => {
	// eslint-disable-next-line no-restricted-globals
	redirectUnauthenticatedToLoginAndReturnAfter(location.pathname, user, navigate);
	const formData = new FormData(event.target);
	if (formData.get("customer_confirm") !== confirm_text) {
		return {
			errors: {
				customer_confirm: `Your confirmation text didn't match ${confirm_text} exactly.`,
			},
		};
	}
	let deleted_user = await fetch(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/delete_user/me`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((response) => {
			if (response.status !== 200) {
				throw JSON.stringify( { status: response.status,response});
			}
			return response.json();
		})
		.catch((error) => {
			return {
				success: true,
			};
		});

	if (deleted_user.success) {
		sessionStorage.clear();
		localStorage.clear();
		// eslint-disable-next-line no-restricted-globals
		location.replace("/");
		return <Loader />
	} else {
		if (deleted_user.error) {
			return {
				errors: {
					deleting_user: deleted_user.error,
				},
			};
		}
		return {
			errors: {
				deleting_user: `The site failed to delete your user. Please refresh or try again later.`,
			},
		};
	}
};

export let meta = ({ data }: { data: SeoDataT }) => {
	let meta_object: metaDataT = {};
	meta_object.title = "Account delete - Fit For Reuse";
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};

// @ts-ignore
export default function Delete({ user, token }) {
	const navigate = useNavigate();
	let [isLoading, setIsLoading] = useState(false);
	const [action_data, setActionData] = useState<{ errors?: {[key: string]: string}}>({})
	const handleOnSubmit = async (event :any) => {
		setIsLoading(true)
		event.preventDefault()
		// @ts-ignore
		setActionData(await action({ user } , event, token, navigate))
		setIsLoading(false)
	}
	return (
		<main className="my_account_content">
			<h1>Delete my account</h1>
			<p>If you delete your account, you will lose access to:</p>
			<ul>
				<li>Documentation</li>
				<li>Checklists</li>
				<li>Your favourites</li>
				<li>Your saved checklists</li>
			</ul>
			<p>
				Type <code>{confirm_text}</code> to confirm.
			</p>
			<form method="post" onSubmit={handleOnSubmit}>
				<Input
					type="text"
					label={false}
					name="customer_confirm"
					isInvalid={() => false}
					autoComplete="off"
					autoCapitalize="off"
					error={action_data?.errors ? action_data?.errors?.customer_confirm : undefined}
				/>
				<Submit className="button delete_confirm">{isLoading ? "Deleting..." : "Confirm"}</Submit>
			</form>
			{action_data?.errors?.deleting_user ? (
				<p className="validation_errors">{action_data?.errors?.deleting_user}</p>
			) : action_data?.errors ? (
				<p className="validation_errors">
					There are one or more validation errors above, you will need to fix these before you can delete your user.
				</p>
			) : null}
		</main>
	);
}
