import React, { CSSProperties } from "react";

export function MediaTextBlock({
	className,
	isMediaOnRight,
	hasFullWidthImg,
	align,
	image,
	style,
	children,
}: {
	className?: string;
	isMediaOnRight?: boolean;
	hasFullWidthImg?: boolean;
	align?: "full" | "wide";
	image: string;
	style?: CSSProperties;
	children: React.ReactNode;
}) {
	let classes = ["wp-block-media-text", "is-stacked-on-mobile"];
	if (className) {
		let extra_classes = className?.split(" ") ?? [];
		classes = classes.concat(extra_classes);
	}
	if (align) {
		classes.push(`align${align}`);
	}
	if (isMediaOnRight) {
		classes.push("has-media-on-the-right");
	}
	if (hasFullWidthImg) {
		classes.push("has-full-width-img");
	}
	return (
		<div className={classes.join(" ")} style={style}>
			<figure className="wp-block-media-text__media" dangerouslySetInnerHTML={{ __html: image }}></figure>
			<div className="wp-block-media-text__content">{children}</div>
		</div>
	);
}
