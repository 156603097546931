import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Dropdown } from "../svg";

export function FFRNavLink(props: NavLinkProps) {
	return (
		<NavLink {...props}>
			<>
				{props.children}
				<Dropdown />
			</>
		</NavLink>
	);
}
