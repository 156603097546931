export function getSectionByURL(url: string) {
	if (url.startsWith("http")) {
		let url_object = new URL(url);
		url = url_object.pathname;
	}
	let url_pieces = url
		.split("/")
		.filter((element) => element !== "documentation")
		.filter((element) => element);
	let section = url_pieces[0];
	let subsection = url_pieces[1];
	return {
		section,
		subsection,
	};
}

export function scrollToNewSection(
	currentParams: { section: string; subsection: string },
	callback: CallableFunction | null = null,
	scroll_behaviour: ScrollBehavior = "smooth"
) {
	let current_section = document.getElementById(currentParams.section + "_" + currentParams.subsection);
	if (current_section) {
		current_section.scrollIntoView({
			behavior: scroll_behaviour,
		});
	} else if (!currentParams?.section) {
		window.scrollTo(0, 0)
	}
}

export function pushSectionToHistory(currentParams: { section: string; subsection: string }, navigate: any) {
	// eslint-disable-next-line no-restricted-globals
	if (`/documentation/${currentParams.section}/${currentParams.subsection}` !== (location.pathname)) {
		let new_url = `/documentation/${currentParams.section}/${currentParams.subsection}`;
		navigate(new_url);
	}
}

export function replaceSectionInHistory(currentParams: { section: string; subsection: string }) {
	let new_url = `/documentation/${currentParams.section}/${currentParams.subsection}`;
	window.history.replaceState({}, "", new_url);
}
