import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import { FavouritedSection } from "../../components/FavouritedSection";
import {redirectUnauthenticatedToLoginAndReturnAfter, update_current_user} from "../../models/User";
import { Favourite } from "../../svg";
import {useLoaderData} from "../../hooks/useLoaderData";

import {metaDataT, SeoDataT} from "../../types/GlobeType";
import Loader from "../../components/Loader";


export let meta = ({ data }: { data: SeoDataT }) => {
	let meta_object: metaDataT = {};
	meta_object.title = "My favourites - Fit For Reuse";
	if (data?.seo?.title) {
		meta_object.title = data.seo.title;
	}
	if (data?.seo?.description) {
		meta_object.description = data.seo.description;
	}
	if (data?.seo?.robots) {
		meta_object.robots = Object.values(data.seo.robots).join(", ");
	}
	return meta_object;
};

export function unfavourite_section(user: User, section: number, token: string) {
	const before_update_user = JSON.parse(JSON.stringify(user));
	// @ts-ignore
	user.meta.favourite_sections = user.meta.favourite_sections.filter(function (array_value) {
		return array_value !== section;
	});

	update_current_user(
		before_update_user,
		{
			meta: {
				favourite_sections: user.meta.favourite_sections,
			},
		},
		token
	);
	return user;
}

export const getFavouritedPosts = (docs_sections: any, user: any) => {
	// @ts-ignore
	const favourited_posts = []
	docs_sections.forEach((section: { posts: any; title: any; }) => {
		let docs_posts_data = section.posts;
		docs_posts_data = docs_posts_data.filter((post: { id: any; }) => {
			return user.meta.favourite_sections.includes(post.id);
		});
		if (docs_posts_data.length > 0) {
			favourited_posts.push({
				title: section.title,
				posts: docs_posts_data,
			});
		}
	});
	// @ts-ignore
	return favourited_posts
}

// @ts-ignore
export default function Index({ user, token }) {
	const [favourited_posts, setFavouritedPosts] = useState<{ title: any; posts: any; }[]>([]);
	const navigate = useNavigate();
	const [favourited_posts_data, isLoading] = useLoaderData(`${process.env.REACT_APP_WORDPRESS_API_URL}fit-for-reuse/v1/get_docs_posts`, {
		sections: []
	},	 {
		Authorization: `Bearer ${token}`,
	});

	useEffect(() => {
		// @ts-ignore
		setFavouritedPosts(getFavouritedPosts(favourited_posts_data.sections, user))
	}, [favourited_posts_data])

	if (!user) {
		// eslint-disable-next-line no-restricted-globals
		redirectUnauthenticatedToLoginAndReturnAfter(location.pathname, user, navigate);
		return null
	}

	const handleRemove = (section_id: number) => {
		const lUser = unfavourite_section(user, section_id, token)
		// @ts-ignore
		setFavouritedPosts(getFavouritedPosts(favourited_posts_data.sections, lUser))
	}

	if (isLoading) {
		return <main className={`my_account_content my_favourites no_favourites`}><Loader /></main>
	}

	return (
		<main className={`my_account_content my_favourites${favourited_posts.length > 0 ? "" : " no_favourites"}`}>
			{favourited_posts.length > 0 ? (
				favourited_posts.map((section) => {
					return (
						<React.Fragment key={section.title}>
							{section.posts.map((post: { id: React.Key | null | undefined; }) => {
								return <FavouritedSection key={post.id} post_data={post} section_title={section.title} onRemove={handleRemove} />;
							})}
						</React.Fragment>
					);
				})
			) : (
				<>
					<Favourite />
					<p className="no_favourites_message">
						Your Favourites section is currently empty, click below to browse documentation and start adding
					</p>
					<Link to="/documentation">
						<button className="button">Add some favourites</button>
					</Link>
				</>
			)}
		</main>
	);
}
