import React from 'react'
import { Link } from 'react-router-dom';
import { Delete } from "../svg";

import { slugify } from "../models/Slugify";
import {favouriteT} from "../types/GlobeType";

export function FavouritedSection({ post_data, section_title, onRemove }: { post_data: any; section_title: string; onRemove: (sectionId: number) => void }) {

	const favourite: favouriteT = {};
	return (
		<article className={`favourited_section${favourite.state === "loading" ? " hidden" : ""}`}>
			<p className="section_title" dangerouslySetInnerHTML={{ __html: section_title }}></p>
			<Link className="post_title" to={`/documentation/${slugify(section_title)}/${slugify(post_data.post_title)}`}>
				<h2 dangerouslySetInnerHTML={{ __html: post_data.post_title }}></h2>
			</Link>
			<p className="last_updated">
				<strong>{post_data.last_updated.prefix}</strong> {post_data.last_updated.date}
			</p>
			<button className="remove_from_favourites" type="submit" onClick={() => { onRemove(post_data.id) }}>
				{favourite.state === "submitting" ? "Removing" : "Remove"} <Delete />
			</button>
		</article>
	);
}
