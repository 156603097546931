import React, {useEffect, useState} from "react";
import {NavLink, Outlet, Link, Routes, Route, useLocation} from "react-router-dom";

import { SocialBar } from "./components/SocialBar";
import { FFRNavLink } from "./components/FFRNavLink";
import { Logo, Dropdown } from "./svg";

import './App.css';
import { User } from "./types/GlobeType";


import Documentation from "./routes/documentation";
import Checklists from "./routes/checklists";
import ChecklistsSlugs from "./routes/checklists/$slug";
import About from "./routes/about";
import Support from "./routes/support/support_type";
import Login from "./routes/login";
import Register from "./routes/register";
import Contact from "./routes/contact";
import Logout from "./routes/logout";
import { get_user, is_user_authenticated } from "./models/User";
import MyAccount from "./routes/my-account";
import Index from "./routes";
import Post from "./routes/$post_slug";
import ForgottenPassword from "./routes/forgotten-password";
import ForgottenPasswordCheckEmail from "./routes/forgotten-password-check-email";
import NewPassword from "./routes/new-password";
import ForgottenPasswordSuccess from "./routes/forgotten-password-success";

function Header({ user }: { user?: User | false }) {
  const [supportSubMenuOpen, setSupportSubMenuOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation()

  useEffect(() => {
      if (location.pathname.includes('faq') || location.pathname.includes('glossary')) {
        setSupportSubMenuOpen(true)
      }
  }, [location])

  function closeMainMenu() {
    setSupportSubMenuOpen(false);
    const menu_toggle = document.getElementById("menu_toggle") as HTMLInputElement | null;
    if (menu_toggle) {
      menu_toggle.checked = false;
    }
      setIsChecked(false)
  }
  function toggle_support_submenu() {
    setSupportSubMenuOpen(!supportSubMenuOpen);
  }

  const handleToggleMenu = () => {
      setIsChecked(!isChecked)
  }

  return (
      <>
        <a className="skip_link screen_reader_only" href="#content">
          Skip to content
        </a>
        <header className="site_header">
          <div className="logo">
            <Link to="/" onClick={closeMainMenu} aria-label="Home link.">
              <Logo />
            </Link>
          </div>
          <div className="main_nav">
            <input checked={isChecked} type="checkbox" className="menu_toggle checked" id="menu_toggle" aria-label="Menu Toggle" />
            <div className="menu_icon" onClick={handleToggleMenu}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <nav className="main_menu">
              <FFRNavLink
                  to="/documentation"
                  className={({ isActive }) => `nav_link${isActive ? " active" : ""}`}
                  onClick={closeMainMenu}
              >
                Documentation
              </FFRNavLink>
              <FFRNavLink
                  to="/checklists"
                  className={({ isActive }) => `nav_link${isActive ? " active" : ""}`}
                  onClick={closeMainMenu}
              >
                Checklists
              </FFRNavLink>
              <FFRNavLink
                  to="/about"
                  className={({ isActive }) => `nav_link${isActive ? " active" : ""}`}
                  onClick={closeMainMenu}
              >
                About
              </FFRNavLink>
              <button
                  id="submenu"
                  className={`nav_link has_children${supportSubMenuOpen ? " submenu_open" : ""}`}
                  onClick={toggle_support_submenu}
              >
                Support
                <Dropdown />
                <ul className={`nav_submenu${supportSubMenuOpen ? " open" : ""}`}>
                  <li>
                    <FFRNavLink
                        to="/support/faq"
                        className={({ isActive }) => `nav_link${isActive ? " active" : ""}`}
                        onClick={closeMainMenu}
                    >
                      FAQs
                    </FFRNavLink>
                  </li>
                  <li>
                    <FFRNavLink
                        to="/support/glossary"
                        className={({ isActive }) => `nav_link${isActive ? " active" : ""}`}
                        onClick={closeMainMenu}
                    >
                      Glossary
                    </FFRNavLink>
                  </li>
                </ul>
              </button>
              <FFRNavLink
                  to="/contact"
                  className={({ isActive }) => `nav_link${isActive ? " active" : ""}`}
                  onClick={closeMainMenu}
              >
                Contact
              </FFRNavLink>
              {user ? (
                  <NavLink to="/my-account/details/" className={() => `nav_link my_account_link`} onClick={closeMainMenu}>
                    <button className="button">My account</button>
                  </NavLink>
              ) : (
                  <NavLink to="/login" className={() => `nav_link my_account_link`} onClick={closeMainMenu}>
                    <button className="button">Login / Register</button>
                  </NavLink>
              )}
            </nav>
            <div className="main_menu_overlay"></div>
          </div>
        </header>
      </>
  );
}

function Footer() {
  return (
      <footer className="site-footer">
        <p className="footer-title">Fit for reuse</p>
        <SocialBar />
        <nav className="footer_menu">
          <Link to="/cookie-policy">Cookie Policy</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/accessibility">Accessibility</Link>
          <span className="site-credit">
					Website by <a href="https://thegroupofseven.co.uk/">The Group of Seven</a>
				</span>
        </nav>
        <div className="copyright">
          <p>Copyright © {(new Date().getFullYear())} Reuse Network, all rights reserved.</p>
          <p>Fit for Reuse is managed by the Reuse Network | Registered Charity No.1090824 | Registered Office: 48-54 West Street, St Philips, Bristol BS2 0BL</p>
        </div>
      </footer>
  );
}

function App() {
  const [user, setUser] = useState<false | User | undefined>(false)
  const [token, setToken] = useState(JSON.parse(sessionStorage.getItem('user') || '{"jwt":""}').jwt)

  useEffect(() => {
    is_user_authenticated().then((jwt) => {
      // @ts-ignore
      get_user({ jwt }).then((user) => {
        // @ts-ignore
        setToken(jwt)
        setUser(user)
      })
    })
  }, [])

    return (
    <div className="app">
      <Header user={user} />
      <div className="site_content" id="content">
        <Routes>
            <Route path="/" element={<Index user={user} token={token} />} />
            <Route path="documentation" element={<Documentation user={user} token={token} />} />
            <Route path="documentation/:section" element={<Documentation user={user} token={token} />} />
            <Route path="documentation/:section/:subsection" element={<Documentation user={user} token={token} />} />
            <Route path="documentation/:section/:subsection/:gallery_or_print" element={<Documentation user={user} token={token} />} />
            <Route path="documentation/:section/:subsection/:gallery_or_print/:gallery_item" element={<Documentation user={user} token={token} />} />
            <Route path="checklists" element={<Checklists user={user} token={token} />} />
            <Route path="checklists/:slug" element={<ChecklistsSlugs user={user} token={token} />} />
            <Route path="about" element={<About />} />
            <Route path="login" element={<Login token={token} />} />
            <Route path="register" element={<Register />} />
            <Route path="contact" element={<Contact />} />
            <Route path="support/:slug" element={<Support user={user} token={token} />} />
            <Route path="my-account" element={<MyAccount user={user} token={token} />} />
            <Route path="my-account/:children" element={<MyAccount user={user} token={token} />} />
            <Route path="logout" element={<Logout token={token} />} />
            <Route path="forgotten-password" element={<ForgottenPassword />} />
            <Route path="forgotten-password-check-email" element={<ForgottenPasswordCheckEmail />} />
            <Route path="new-password" element={<NewPassword />} />
            <Route path="forgotten-password-success" element={<ForgottenPasswordSuccess />} />
            <Route path="*" element={<h1>404 Not Found.</h1>} />
            <Route path="/:slug" element={<Post user={user}/>} />
        </Routes>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;
